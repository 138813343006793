import { useEffect, useState } from "react";
import { getMembersByBusinessIdService, addMemberService, memberDeleteService } from "../../services";
import useToast from "../../utils/customHooks/useToast";
import { Modal } from "react-bootstrap";
import PhoneNumber from "../../components/PhoneNumber";
import {role as roleList} from "../../utils/constant";
import { separateCountryCodeAndNumber } from "../../utils/helper";
import { RemoveIcon } from "../../components/SVG";
import searchIcon from "../../assets/dashboard/Search.svg";


const TeamMembers = () => {
    const authUserData = JSON.parse(localStorage.getItem("authUserData")) || null;
    const token = localStorage.getItem('token');
    const showToast = useToast();

    const [memberFormModal, setMemberFormModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [list, setList] = useState([]);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [role, setRole] = useState('');
    const [email, setEmail] = useState('');
    const [phone_number, setPhone_number] = useState('');
    const [address, setAddress] = useState('');


    const [firstNameErr, setFirstNameErr] = useState('');
    const [lastNameErr, setLastNameErr] = useState('');
    const [roleErr, setRoleErr] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [phone_numberErr, setPhone_numberErr] = useState('');
    const [addressErr, setAddressErr] = useState('');

    const [searchTerm, setSearchTerm] = useState('');




    const getAllTeams = async () => {
        const businessId = authUserData?.businessDetail?.businessId;
        if (businessId) {
          try {
            const res = await getMembersByBusinessIdService(businessId);
            if (res.data.status === 1) {
              const members = res.data.responseData.members || [];
              setList(members);
            }
          } catch (error) {
            console.error("Error fetching clients:", error);
          }
        }
    };
    
    useEffect(() => {
        getAllTeams();
    }, []);



    const handleClose = () => {
        setMemberFormModal(false)
    }




    const handleValidate = () => {
        let validate = true;

        if(!firstName){
            setFirstNameErr('First name is required');
            validate = false
        }


        if(!lastName){
            setLastNameErr('Last name is required');
            validate = false
        }

        if(!email){
            setEmailErr('Email is required');
            validate = false
        }

        if(!phone_number){
            setPhone_numberErr('Phone number is required');
            validate = false
        }

        if(!address){
            setAddressErr('Address is required');
            validate = false
        }

        if(!role){
            setRoleErr('Role is required');
            validate = false
        }

        return validate
    }



    const handleSubmit = async () => {
        if(handleValidate()){
            const { cc, number } = separateCountryCodeAndNumber(phone_number);
            let params = {
                firstName,
                lastName,
                role,
                phone_number: number,
                country_code: cc,
                email,
                address,
                businessId: authUserData.businessDetail.businessId
            }
            setIsSubmitting(true);
            try {
                const res = await addMemberService(params);
                if (res?.data?.status) {
                    getAllTeams();
                    showToast(`Member Add successfully`, "success");
                }
            } catch (error) {
                console.error(error);
            }
            setIsSubmitting(false);
        }
    };


    const handleDelete = item => {
        memberDeleteService(item.id).then(res => {
            if (res?.data?.status) {
                getAllTeams();
                showToast(`Member Delete successfully`, "success");
            }
        })
    }


    const filteredList = list.filter(item =>
        item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.email.toLowerCase().includes(searchTerm.toLowerCase())
    );



    return (
        <div className="setting_inner_page">
            <div className="d-flex justify-content-between">
                <div>
                    <h3 className="mb-0">Team Members</h3>
                    <p className="mb-3"><small>Add team members that belong to your company here. Once added, these team members can be added to any project from "Project Team" section inside a project.</small></p>
                </div>
                <div className="ps-5">
                    <span onClick={() => setMemberFormModal(true)} style={{width: '200px'}} className="btn btn-primary">Add Team Member</span>
                </div>
            </div>

            <div className="d-flex justify-content-between align-items-center mt-5">
                <div className="cm_search">
                    <img src={searchIcon} alt="searchicon" />
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Search"
                        value={searchTerm} // Bind input to searchTerm
                        onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm on input change
                    />
                </div>
              
            </div>


            <div className="cm_table_view mt-4 mb-5">
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th className="ps-2">Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Role</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                filteredList.map((item, index) => (
                                    <tr key={item.id}>
                                        <td></td>
                                        <td className="ps-2">{(!item.firstName && !item.lastName) ? '-' : item.firstName} {item.lastName}</td>
                                        <td>{item.email || ''}</td>
                                        <td>{item.phone_number || '-'}</td>
                                        <td>{item.role || 'Client'}</td>
                                        <td className="text-center">
                                            <span onClick={() => handleDelete(item)} className="cursor-pointer"><RemoveIcon /></span>
                                        </td>
                                    </tr>
                                ))
                            }
                            
                            
                        </tbody>
                    </table>
                </div>
            </div>



            <Modal className="medium_padding" show={memberFormModal} onHide={handleClose}  centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Team Member</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-5">
                    <div className="form-group mb-4">
                        <label>First Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            value={firstName}
                            onChange={e => (setFirstName(e.target.value), setFirstNameErr(''))}
                        />
                        {firstNameErr && <p className="cm_err">{firstNameErr}</p>}
                    </div>

                    <div className="form-group mb-4">
                        <label>Last Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={e => (setLastName(e.target.value), setLastNameErr(''))}
                        />
                        {lastNameErr && <p className="cm_err">{lastNameErr}</p>}
                    </div>


                    <div className="form-group mb-4">
                        <label>Email</label>
                        <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            value={email}
                            onChange={e => (setEmail(e.target.value), setEmailErr(''))}
                        />
                        {emailErr && <p className="cm_err">{emailErr}</p>}
                    </div>

                    <div className="form-group mb-4">
                        <label>Phone Number</label>
                        <PhoneNumber
                     
                            value={phone_number}
                            setValue={(value) => (setPhone_number(value), setPhone_numberErr(''))}
                            isError={!!phone_numberErr}
                            setError={() => {}}
                            isSubmitTouch={true}/>

                        {phone_numberErr && <span className="cm_err">{phone_numberErr}</span>}
                    </div>

                    <div className="form-group mb-4">
                        <label>Address</label>
                        <input
                            type="text"
                            value={address}
                            onChange={e => (setAddress(e.target.value), setAddressErr(''))}
                            className="form-control"
                            placeholder="Address"  />

                        {addressErr && <span className="cm_err">{addressErr}</span>}
                    </div>


                    <div className="form-group mb-4">
                        <label>Role</label>
                        <select
                        value={role}
                        onChange={e => (setRole(e.target.value), setRoleErr(''))}
                        className="form-control"
                        placeholder="Role">
                            {roleList.map(item => (<option value={item.label}>{item.label}</option>))}
                        </select>
                       

                        {roleErr && <span className="cm_err">{roleErr}</span>}
                    </div>

            

                    <div className="d-flex align-items-center justify-content-between mt-5">
                        <span className="btn btn-dark px-5">Cancel</span>
                        <button disabled={isSubmitting} onClick={handleSubmit} className="btn btn-primary px-5">Add</button>
                    </div>
                </Modal.Body>
            </Modal>
            
        </div>
    );
};

export default TeamMembers;







