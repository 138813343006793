import { React, useEffect, useState } from "react";
import searchicon from "./../../assets/dashboard/Search.svg";
import userimg from "./../../assets/dashboard/user_img.svg";
import CommonButton from "../../components/common/CommonButton";
import plus from "./../../assets/dashboard/plus.svg";
import filterr from "./../../assets/dashboard/filter.svg";
import filtericon from "./../../assets/dashboard/filter_icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import ProjectFilter from "../../modals/ProjectFilter";
import { getBusinessProjectsService, getProjectByBusinessService } from "../../services";
import { phaseClass, projectStage, projectStatus, statusClass } from "../../utils/constant";
import AddNewProject from "../../modals/AddNewProject";
import ClientDetails from "../../modals/ClientDetails";
import Customize from "../../modals/Customize";
import Loader from "../../components/common/Loader";
import { useSelector } from "react-redux";
import CustomizePipeline from "../../modals/CustomizePipeline";
import { logout } from "../../features/auth/authSlice";
import { persistor } from "../../store";
import { useDispatch } from "react-redux";
import GlobalSearch from "../../components/common/GlobalSearch";
import { addProjects } from "../../features/projects/projectsSlice";
import { formatDate, formatDate2 } from "../../utils/helper";


const Projects = () => {
  const navigate = useNavigate();
  let userDetail = JSON.parse(localStorage.getItem("authUserData")) || null;
  const [pipeLine, setPipeLine] = useState([])
  const [activeIndex, setActiveIndex] = useState(null);
  const [showProjectFilter, setShowProjectFilter] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState([])
  const [bussinessId, setBussinessId] = useState(null);
  const [showCustomize, setShowCustomize] = useState(false);
  const [searchProjects, setSearchProjects] = useState([])
  const [isFiterRun, setIsFilterRun] = useState(false)
  const [isFilterActivated, setIsFilterActivated] = useState(false)
  
  const [allProjects, setAllProjects] = useState([]);
  const [showProjectForm, setShowProjectForm] = useState(false);
  const [isClientDetailsModalOpen, setClientDetailsModalOpen] = useState(false);
  
  const [searchTerm, setSearchTerm] = useState(""); // Add state for search term
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(""); // State to hold debounced search term
  
  // States for customizing table columns
  const [projectManagerChecked, setProjectManagerChecked] = useState(true);
  const [statusChecked, setStatusChecked] = useState(true);
  const [clientChecked, setClientChecked] = useState(true);
  const [dueDateChecked, setDueDateChecked] = useState(true);
  const [phaseChecked, setPhaseChecked] = useState(true);
  const [savedFilters, setSavedFilters] = useState(null);
  const [projectNameChecked, setProjectNameChecked] = useState(true);
  const [sendTemplateChecked, setSendTemplateChecked] = useState(true);
  const [filterError, setFilterError] = useState(false);

  const phase = useSelector(state => state?.phases?.phases?.phase);
  const _id = useSelector(state => state?.phases?.phases?.id)
  const _businessId = useSelector(state => state?.phases?.phases?.businessId)
  const _status = useSelector(state => state?.status?.status?.status)
  const projects = useSelector(state => state?.projects?.projects)
  const location = useLocation()
  const {_index, _currenStageName_} = location.state || {}
  const dispatch = useDispatch()
  const [items, setItems] = useState(
    phase && phase.map((item, index) => ({
      id: (index + 1).toString(),
      name: item.name,
      color: item.color,
      checked: true
    })) || [])


    const initialStatus = [
      ...(_status?.map(item => ({
        statusName: item.name,
        status: false,
      })) || []),
      ...projectStatus.map(item => ({
        statusName: item.label,
        status: false,
      }))
    ];
    
    const initialPhase = [
      ...(phase?.map(item => ({
        phaseName: item.name,
        status: false,
      })) || []),
      ...projectStage.map(item => ({
        phaseName: item.label,
        status: false,
      }))
    ];

  const [filter, setFilter] = useState({
      status: initialStatus.reduce((acc, item) => {
        acc[item.statusName] = item.status;
        return acc;
      }, {}),
      phase: initialPhase.reduce((acc, item) => {
        acc[item.phaseName] = item.status;
        return acc;
      }, {})
    });

    useEffect(() => {

      // getAllProjects()
      if(projects){
        setAllProjects(projects || [])
        setFilteredProjects(projects|| [])
        const pipeLineData = getProjectStagesWithTotal(projects)
        localStorage.setItem('pipeLineData', JSON.stringify(pipeLineData))
        setPhase(pipeLineData)
      }else{
        let _pipeLineData = JSON.parse(localStorage.getItem('pipeLineData')) || []
        if(_pipeLineData.length > 0){
          setPhase(_pipeLineData)
        }
      }
      localStorage.removeItem('ProjectDetails')
      localStorage.removeItem('projectDetail')

      if(_index === undefined && _currenStageName_ === undefined){
        setActiveIndex(0)
        setBussinessId('All Projects')
      }else{
        // setActiveIndex(_index)
        setActiveIndex(findPhaseIndexByName(_currenStageName_))
        setBussinessId(_currenStageName_)
        handleItemClick(findPhaseIndexByName(_currenStageName_),_currenStageName_)
      }
      
    },[])

    useEffect(() => {
      const updatedPhase = [
        ...(phase?.map(item => ({
          phaseName: item.name,
          status: false,
        })) || []),
        ...projectStage.map(item => ({
          phaseName: item.label,
          status: false,
        }))
      ];
    
      setFilter(prevFilter => ({
        ...prevFilter,
        phase: updatedPhase.reduce((acc, item) => {
          acc[item.phaseName] = item.status;
          return acc;
        }, {})
      }));
    }, [phase]);

  const [showCustomizePipeline, setShowCustomizePipeline] = useState(false);

  useEffect(() => {
    if(isFiterRun){
      setActiveIndex(0);
      setBussinessId('All Projects');
      applyFilter();
    }

  }, [filter]);
  
  const [nameInitials, setNameInitials] = useState('');

  // Debouncing useEffect
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300); // Adjust the debounce delay as needed (300ms in this case)
  
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    let checkedValues = JSON.parse(localStorage.getItem('filterCheks'))
    if(checkedValues){
      setSavedFilters(checkedValues)
    }
  }, [localStorage.getItem('filterCheks')]);
  
  useEffect(() => {
    if (debouncedSearchTerm) {
      // const filtered = filteredProjects.filter(project =>
      //   project.projectName.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      // );
      const searchTermLower = debouncedSearchTerm.toLowerCase();
      const filtered = filteredProjects.filter(project => {
        const projectNameMatch = project.projectName.toLowerCase().includes(searchTermLower);
        const clientNameMatch = project.clients[0]?.clientName.toLowerCase().includes(searchTermLower);
        return projectNameMatch || clientNameMatch;
      });
      // setFilteredProjects(filtered);
      setSearchProjects(filtered)
    } else {
      // setActiveIndex(0)
      // setFilteredProjects(allProjects);
      handleItemClick(activeIndex, bussinessId)
    }
  }, [debouncedSearchTerm]);
  
  const handleCustomizeSubmit = (customizeState) => {
    setProjectManagerChecked(customizeState.projectManagerChecked);
    setStatusChecked(customizeState.statusChecked);
    setClientChecked(customizeState.clientChecked);
    setDueDateChecked(customizeState.dueDateChecked);
    setPhaseChecked(customizeState.phaseChecked);
    setProjectNameChecked(customizeState.projectNameChecked);
    setSendTemplateChecked(customizeState.sendTemplateChecked);
    
    setShowCustomize(false);
  };
  
  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
    persistor.purge() // Clear persisted state
    navigate("/signin");
  };
  
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleShow = () => setShowProjectForm(true);


  const handleItemClick = (index, _currebStageName) => {
    // setFilter({
    //   status: initialStatus.reduce((acc, item) => {
    //     acc[item.statusName] = item.status;
    //     return acc;
    //   }, {}),
    //   phase: initialPhase.reduce((acc, item) => {
    //     acc[item.phaseName] = item.status;
    //     return acc;
    //   }, {})
    // });
    // setIsFilterActivated(false)
    // setIsFilterRun(false)
    setSearchTerm('')
    if (_currebStageName === 'All Projects' && index === 0) {
      setActiveIndex(0);
      // getAllProjects();
      setBussinessId('All Projects');
      if(isFilterActivated === false){
         if(allProjects && allProjects.length > 0){
           setFilteredProjects([...allProjects])
         }else{
           setFilteredProjects([...projects])
         }
      }else{
        // setFilteredProjects(filteredProjects);
        applyFilter()
      }
    } else {
      let _filteredProjects

      //Added *isFilterActivated* checked so that we know filtered has already applied and we dont have to fetch value from allProjects or from redux saved project,
      // It will go else condition if filter has been apllied so that it will fetch value only from filterd array
      if(isFilterActivated === false){
        if(index !== null && _currebStageName !== null ){

          // Filter the projectData based on the projectStage

          //Added *projects* checked because we have to make sure that on each phase click filteration,
          // user can see desire result seamlessly, 
          //that is why we are not filtering from *allProjects* if data is availible on redux projects
          if(projects){
            _filteredProjects = projects.filter(project => project.projectStage.name === _currebStageName);
          }else{
            _filteredProjects = allProjects.filter(project => project.projectStage.name === _currebStageName);
          }
          // Update the state with the filtered projects
          setBussinessId(_currebStageName);
          setActiveIndex(index); // Update the active item on click
          setFilteredProjects(_filteredProjects); // Assuming you have a state to hold the filtered projects
        }
      }else{
        // _filteredProjects = filteredProjects.filter(project => project.projectStage === _currebStageName);
        applyFilter()
         // Update the state with the filtered projects
          setBussinessId(_currebStageName);
          setActiveIndex(index); 
          // setFilteredProjects(_filteredProjects);
      }
    }
  };


  function getProjectStagesWithTotal(projectData) {
      const projectStageMap = {};

      // Iterate through the projects and count occurrences of each projectStage
      projectData.forEach(project => {
          const stage = project.projectStage.name;
          if (projectStageMap[stage]) {
              projectStageMap[stage]++;
          } else {
              projectStageMap[stage] = 1;
          }
      });

      // Calculate the total occurrences
      const totalOccurrences = Object.values(projectStageMap).reduce((sum, count) => sum + count, 0);

      // Convert the map to an array of objects
      const projectStageArray = Object.keys(projectStageMap).map(stage => ({
          name: stage,
          occurrence: projectStageMap[stage]
      }));

      // Add the "All" entry at the beginning
      projectStageArray.unshift({ name: 'All Projects', occurrence: totalOccurrences });
    return projectStageArray;
  } 

  const getAllProjects = () => {
          try {
            let userData = JSON.parse(localStorage.getItem('authUserData'))
            let businessID = userData.businessDetail.businessId
            if(businessID){

              getBusinessProjectsService(businessID).then(res => {
                if(res.statusCode === 200){
                if(res.data.status === 1){
                setAllProjects(res.data.responseData.projectData || [])
                setFilteredProjects(res.data.responseData.projectData || [])
                const pipeLineData = getProjectStagesWithTotal(res.data.responseData.projectData)
                localStorage.setItem('pipeLineData', JSON.stringify(pipeLineData))
                setPhase(pipeLineData)
                dispatch(addProjects(res.data.responseData.projectData))
                // setPipeLine(pipeLineData)
                }}})
              }
            } catch (error) {}
  }

  // const setPhase = (data) => {
  //   console.log(data,'dataaaaaaaaaaaaa')

  //   const allOccurrenceData = data.find(phaseData => phaseData.name === "All Projects");
  //   console.log(allOccurrenceData,'allOccurrenceData allOccurrenceData')

  //   const allOccurrence = allOccurrenceData ? allOccurrenceData.occurrence.toString().padStart(2, "0") : "00";
  //   console.log(allOccurrence,'allOccurrence allOccurrence')

  //   if(phase){
  //     console.log('inside if phase availible')
  //     const phasesArray = new Array(phase.length + 1); // Create an array with length equal to the number of phases plus 1 for "All"
  //     console.log(phasesArray,'intial phasesArray')
  //     phasesArray[0] = { name: "All Projects", occurrence: allOccurrence };
  //     // phasesArray.unshift({ name: "All Projects", occurrence: allOccurrence })
  //     console.log(phasesArray,'phasesArray phasesArray  phasesArray...................')
  //     phase.forEach((item) => {
  //       phasesArray[item.position] = data.find(phaseData => phaseData.name === item.name) || { name: item.name, occurrence: "00" };
  //     });

  //     console.log(phasesArray,'setPipeLine setPipeLine setPipeLine')
  
  //     setPipeLine(phasesArray)
  //   }
  
  // }

  const setPhase = (data) => {
      console.log(data, 'dataaaaaaaaaaaaa');

      const allOccurrenceData = data.find(phaseData => phaseData.name === "All Projects");
      console.log(allOccurrenceData, 'allOccurrenceData allOccurrenceData');

      const allOccurrence = allOccurrenceData ? allOccurrenceData.occurrence.toString().padStart(2, "0") : "00";
      console.log(allOccurrence, 'allOccurrence allOccurrence');

      if (phase) {
          console.log('inside if phase available');
          const phasesArray = Array(phase.length + 1).fill(null); // Initialize the array with null values
          console.log(phasesArray, 'initial phasesArray');
          phasesArray[0] = { name: "All Projects", occurrence: allOccurrence }; // Set the first element
          console.log(phasesArray, 'phasesArray after setting All Projects');

          phase.forEach((item) => {
              const phaseData = data.find(phaseData => phaseData.name === item.name);
              phasesArray[item.position + 1] = phaseData ? { name: item.name, occurrence: phaseData.occurrence.toString().padStart(2, "0") } : { name: item.name, occurrence: "00" };
          });

          console.log(phasesArray, 'setPipeLine setPipeLine setPipeLine');
          setPipeLine(phasesArray);
      }
  };


  const handleProjectFilterClick = () => {
    setShowProjectFilter(true);
    setShowCustomize(false)
  };

  const handleProjectCustomizeClick = () => {
    setShowCustomize(true);
    setShowProjectFilter(false)
  }

  const handleFilterClose = () => {
    setShowProjectFilter(false);
  };

  const handleCustomizeClose =()=>{
    setShowCustomize(false);
  }

  const handleProjectNameClick = (index) => {
    localStorage.removeItem('crSchema');
    let _projectDetail = null
    if(filteredProjects.length > 0 && searchTerm === ''){
        _projectDetail = filteredProjects[index]
    }else{
      _projectDetail = searchProjects[index]
    }
    console.log(_projectDetail,'_projectDetail _projectDetail _projectDetail')
    localStorage.setItem('projectDetail',JSON.stringify(_projectDetail))
    setTimeout(() => {
      navigate("/projectdetails", { state : { id: _projectDetail.id}})
    },1000)
  };

  const applyFilter = () => {
    const statusFiltersActive = Object.values(filter.status).some(value => value);
    const phaseFiltersActive = Object.values(filter.phase).some(value => value);
  
    console.log(statusFiltersActive, 'statusFiltersActive');
    console.log(phaseFiltersActive, 'phaseFiltersActive');
  
    if (!statusFiltersActive && !phaseFiltersActive) {
      setIsFilterActivated(false); // If there is no filter then setting *isFilterActivated* false.
      setFilterError(false); // If there is no filter then removing filter error message by setting *filterError* false.
      setIsFilterRun(false); // If there is no filter then we are setting that filter has never run and its dependent functionalities will not cause re-rendering or uncoditional/unwanted changes in UI.
      setFilteredProjects(allProjects);
      return;
    }
  
    setIsFilterActivated(true);
    
    const filtered = allProjects.filter((project) => {
      console.log(project,'project.................')
      const statusFilter = Object.entries(filter.status).some(([key, value]) => value && project.projectStatus.name === key);
      const phaseFilter = Object.entries(filter.phase).some(([key, value]) => value && project.projectStage.name === key);
  
      if (statusFiltersActive && phaseFiltersActive) {
        // Both status and phase filters are active, use AND condition
        return statusFilter && phaseFilter;
      } else {
        // Either status or phase filters are active, use OR condition
        return statusFilter || phaseFilter;
      }
    });
  
    if (filtered.length === 0) {
      setFilterError(true);
    } else {
      setFilterError(false);
    }
    setFilteredProjects(filtered);
  };
  

  const handleCustomizePipelineClick = () => {
      setShowCustomizePipeline(true);
    };

    const _handleCustomizeClose =() => {
      setShowCustomizePipeline(false);
    }

    const _handleCustomizePiplelineSubmit = (customizeState) => {
      setItems(customizeState)
      setShowCustomizePipeline(false);
    };
    // Name Intital
    useEffect(() => {
      const userData = localStorage.getItem('authUserData');  
      if (userData) {
        const parsedData = JSON.parse(userData);
        const { firstName, lastName } = parsedData;
        if (firstName && lastName) {
          const firstInitial = firstName.charAt(0).toUpperCase();
          const lastInitial = lastName.charAt(0).toUpperCase();
          setNameInitials(`${firstInitial}${lastInitial}`);
        }
      }
    }, []);


    const renderPhases = () => {
      const allOccurrenceData = pipeLine.find(
          (phaseData) => phaseData.name === "All Projects"
      );
      const allOccurrence = allOccurrenceData
          ? allOccurrenceData.occurrence.toString().padStart(2, "0")
          : "00";


      if(phase){

        const phasesArray = new Array(phase.length + 1);
        phasesArray[0] = { name: "All Projects", occurrence: allOccurrence, checked: true, color:'' };

        phase.forEach((item, index) => {
            const phaseData =
                pipeLine.find((phaseData) => phaseData?.name === item?.name) || {
                    name: item.name,
                    occurrence: "00",
                };
            const isChecked = items[index] ? items[index].checked : false;
            const color = item.color
            phasesArray[index + 1] = { ...phaseData, id: index + 1, checked: isChecked, color };
        });
        return phasesArray
            .filter((phase) => phase && phase.checked)
            .map((phase, index) => (
                <li className={`arrow_scroll_li ${index === activeIndex ? 'active_scroll_li' : ''}`} key={index} onClick={() => handleItemClick(index, phase.name)}>
                    <h5>{phase.occurrence.toString().padStart(2, "0")}</h5>
                    <p style={{color : phase.color}}>{phase.name}</p>
                </li>
            ));
      }

    };

  function findPhaseIndexByName(name) {
    if(phase){
      const lowerCaseName = name.toLowerCase();
      const index = phase.findIndex(phase => phase.name.toLowerCase() === lowerCaseName);
      return index === -1 ? 0 : index + 1;
    }else{
      return 0
    }
  }

  function getColorByName(name) {
    const trimmedName = name?.trim().toLowerCase();
    if(phase){
      const phaseItem = phase.find(phase => phase.name.toLowerCase() === trimmedName);
      return phaseItem ? phaseItem.color : 'grey';
    }else{
      return 'grey'
    }
  }



  return (
    <div>


        <div className="dashboard_upper projectdetails_dash_upper">

        <div className="projectdetails_dash_left">
            <h3>{userDetail?.businessDetail?.businessName}</h3>
        </div>
        <div className="projectdetails_dash_left">

          <GlobalSearch />
            <div className="logout_main_div">
              <div className="name_intials_dash_upper">{nameInitials}</div>
              <div className="logout_div">
                <p onClick={handleLogout}>Logout</p>
              </div>
            </div>

        </div>
      </div>
      

      <div className="dashboard_lower">
        <div className="dashboard_sub">
          <div className="dashboard_sub_left heading_symbol">
            <h3 className="dashboard_heading">Pipeline</h3>
          </div>
          <div className="dashboard_sub_right">
            <CommonButton
              label="Customize"
              fontSize="14px"
              fontWeight="700"
              className="dash_common_btn white_bg_btn"
              icon={filterr}
              onClick={handleCustomizePipelineClick}
            />
            {showCustomizePipeline && (
                <CustomizePipeline 
                handleCustomizeClose={_handleCustomizeClose} 
                handleCustomizePiplelineSubmit={_handleCustomizePiplelineSubmit}
                phase={phase}
                id={_id}
                businessId={_businessId}
                initialState={items}/>
                
              )}
            <CommonButton
              label="Add New Project"
              fontSize="14px"
              fontWeight="700"
              className="dash_common_btn"
              icon={plus}
              onClick={handleShow}
            />
          </div>
        </div>
        <ul className="arrow_scroll_ul">
          {renderPhases()}
        </ul>
        <div className="mt-5">
          <h3 className="dashboard_heading">Details</h3>
          <div className="project_main_upper">
            <div className="dashboard_upper_input">
              <input type="text" 
              placeholder="Search" 
              value={searchTerm} // Bind searchTerm to input value
              onChange={handleSearchChange} // Handle input changes
              />
              <img src={searchicon} />
            </div>
            <div className="project_main_upper_btn">
              <CommonButton
                label="Filter"
                fontSize="14px"
                fontWeight="700"
                className="dash_common_btn white_bg_btn"
                icon={filtericon}
                onClick={handleProjectFilterClick}
              />
              {showProjectFilter && (
                <ProjectFilter 
                handleFilterClose={() => {setShowProjectFilter(false); setIsFilterRun(true)}}
                filterBy={filter}
                setFilterBy={setFilter}
                saveData={savedFilters}
                />
              )}
              <CommonButton
                label="Customize"
                fontSize="14px"
                fontWeight="700"
                className="dash_common_btn   white_bg_btn"
                icon={filterr}
                onClick={handleProjectCustomizeClick}
              />
              {showCustomize && (
                <Customize 
                handleCustomizeClose={handleCustomizeClose} 
                handleCustomizeSubmit={handleCustomizeSubmit}
                initialState={{
                  projectManagerChecked,
                  statusChecked,
                  clientChecked,
                  dueDateChecked,
                  phaseChecked,
                  projectNameChecked,
                  sendTemplateChecked,
                }}/>
              )}
            </div>
          </div>
          {/* <div className="applied-filters">
            <h4>Applied Filter :</h4>
            <div className="status">STATUS : /</div>
            <div className="phase">PHASE :</div>
          </div> */}
          <div
            className="project_main_table"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            <table className="project_table">
              <thead>
                <tr>
                  {projectNameChecked && <th>Project Name</th>}
                  {dueDateChecked && <th>Due Date</th>}
                  {clientChecked && <th>Client</th>}
                  {statusChecked && <th>Status</th>}
                  {phaseChecked && <th>Phases</th>}
                  {projectManagerChecked && <th>Project Manager</th>}
                  {sendTemplateChecked &&<th>Send Template</th>}
                </tr>
              </thead>
              <tbody>
                  {searchTerm === '' ?
                  filteredProjects && filteredProjects.length > 0 ? 
                  filteredProjects.map((project, index) => (
                    <tr key={index}>
                      {projectNameChecked && (
                        <td
                          className="_pointer_"
                          onClick={() => handleProjectNameClick(index)}
                        >
                          {project.projectName}
                        </td>
                      )}
                      {dueDateChecked && <td>{project.projectDueDate ? formatDate2(project.projectDueDate) : '-'}</td>}

                      {/* {dueDateChecked && <td>-</td>} */}
                      {clientChecked && (
                        <td>
                          {project.clients.length > 0 && project.clients[0].clientName ? project.clients[0].clientName: "N/A"}
                        </td>
                      )}
                      {statusChecked && (
                        // <td className={`status ${statusClass[project.projectStatus.name.toLowerCase()]}`}>
                        <td className={`status`} style={{color: project.projectStatus.color}}>
                          <span style={{backgroundColor: project.projectStatus.color}}></span>
                          {project.projectStatus.name}
                        </td>
                      )}
                      {phaseChecked && (
                        <td>
                          <p
                            className="phases grey_phases"
                            style={{backgroundColor : getColorByName(project.projectStage.name)}}
                          >
                            {project.projectStage.name}
                          </p>
                        </td>
                      )}
                      {projectManagerChecked && <td>
                          {project.members.length > 0 && project.members.filter(m => m.role == 'Manager').length ? project.members.filter(m => m.role == 'Manager').map((item, itemIndex) => (
                            <p key={itemIndex}>{item.firstName} {item.lastName}</p>
                          )) : 'N/A'}
                        </td>}
                      {sendTemplateChecked && <td>N/A</td>}
                    </tr>
                  ))
                : 
                <tr>
                  <td colspan="7" className="loader_table">
                    {filterError ? 'No results match the filter criteria' : 'NO DATA FOUND'}
                  </td>
                </tr>
                :
                searchProjects.length > 0 ? 
                searchProjects.map((project, index) => (
                    <tr key={index}>
                      {projectNameChecked && (
                        <td
                          className="_pointer_"
                          onClick={() => handleProjectNameClick(index)}
                        >
                          {project.projectName}
                        </td>
                      )}
                      {/* {dueDateChecked && <td>{formatDate(project.created_at)}</td>} */}
                      {dueDateChecked && <td>{project.projectDueDate ? project.projectDueDate.split('T')[0] : '-'}</td>}
                      {clientChecked && (
                        <td>
                          {project.clients.length > 0 &&
                          project.clients[0].clientName
                            ? project.clients[0].clientName
                            : "N/A"}
                        </td>
                      )}
                      {statusChecked && (
                        <td className={`status`} style={{color: project.projectStatus.color}}>
                          <span style={{backgroundColor: project.projectStatus.color}}></span>
                          {project.projectStatus.name}
                        </td>
                      )}
                      {phaseChecked && (
                        <td>
                          <p
                            className="phases grey_phases"
                            style={{backgroundColor : getColorByName(project.projectStage.name)}}
                          >
                            {project.projectStage.name}
                          </p>
                        </td>
                      )}
                      {projectManagerChecked && <td>{project.projectManager || "N/A"}</td>}
                      {sendTemplateChecked && <td>N/A</td>}
                    </tr>
                  ))
                : 
                <tr>
                  <td colspan="7" className="loader_table">
                    {/* <Loader/> */}
                    NO DATA FOUND
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showProjectForm && (
        <AddNewProject
          onHide={() => setShowProjectForm(false)}
          isNext={isClientDetailsModalOpen}
          openClientDetailModal={() => setClientDetailsModalOpen(true)}
          isOpen={showProjectForm}
        />
      )}

      {isClientDetailsModalOpen && (
        <ClientDetails
          _refreshPipeLine={() => {console.log('')}}
          from="projectDetails"
          callProjectList={getAllProjects}
          openProject={() => setShowProjectForm(true)}
          onHide={() => setClientDetailsModalOpen(false)}
          isOpen={showProjectForm}
        />
      )}
    </div>
  );
};

export default Projects;
