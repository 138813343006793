import { format, parseISO } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Modal, Tab, Tabs } from "react-bootstrap";
import editBlackIcon from "../../../assets/logos/edit_black_icon.svg";
import editIcon from "../../../assets/logos/edit_icon.svg";
import threeDotIcon from "../../../assets/logos/three-dot.svg";
import CommonButton from "../../../components/common/CommonButton";
import CustomTooltip from "../../../components/common/CustomTooltip";
import Loader from "../../../components/common/Loader";
import ActionModal from "../../../modals/ActionModal";
import {
  deleteExpenses,
  getExpensesList,
  remindExpenses,
  updateExpense,
  getProjectByBusinessService
} from "../../../services/index";
import { _contractStatus, contractStatus } from "../../../utils/constant";
import useToast from "../../../utils/customHooks/useToast";
import searchIcon from "./../../../assets/dashboard/Search.svg";
import expensesIcon from "./../../../assets/dashboard/expenses_blue.svg";
import filterIcon from "./../../../assets/dashboard/filter_icon.svg";
import plusIcon from "./../../../assets/dashboard/plus.svg";
import ChangeRequestFilter from "./../../../modals/ChangeRequestFilter";
import AddExpense from "./AddExpense";
import ResubmitExpense from "./ReSubmitExpense";
import ReviewExpense from "./ReviewExpense";
import ViewExpense from "./ViewExpense";

const formatAmountInINR = (amount) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

const dateRender = (date) => {
  try {
    return format(parseISO(date?.replace("Z", "")), "MMMM dd, yyyy");
  } catch (error) {
    return date;
  }
};

const Expenses = ({ activeTab, isGlobal }) => {
  let userDetail = JSON.parse(localStorage.getItem("authUserData")) || null;
  const projectDetails = JSON.parse(localStorage.getItem('projectDetail'));

  const [showExpensesModal, setShowExpensesModal] = useState(false);
  const [showExpensesFilter, setShowExpensesFilter] = useState(false);
  const [addExpense, setAddExpense] = useState(false);
  const [viewExpense, setViewExpense] = useState(false);
  const [resubmitExpense, setResubmitExpense] = useState(false);
  const [reviewExpense, setReviewExpense] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notShowAgain, setNotShowAgain] = useState(false);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [expenseId, setExpenseId] = useState(null);
  const [expenseTitle, setExpenseTitle] = useState("");
  const [expenseButtons, setExpenseButtons] = useState("");
  const [activeSubTab, setActiveSubTab] = useState("myExpenses");
  const [searchTitle, setSearchTitle] = useState("");
  const [filterStatus, setFilterStatus] = useState([
    ...contractStatus.map((item) => ({ ...item, isChecked: false })),
  ]);
  const [myExpensesList, setMyExpensesList] = useState([]);
  const [myExpensesListFilter, setMyExpensesListFilter] = useState([]);
  const [expensesForReviewList, setExpensesForReviewList] = useState([]);
  const [expensesForReviewListFilter, setExpensesForReviewListFilter] = useState([]);
  const [staticDetails, setStaticDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [expenseReviewData, setExpenseReviewData] = useState({});
  const [viewExpenseData, setViewExpenseData] = useState({});
  const [resubmitExpenseData, setResubmitExpenseData] = useState({});
  const [projectsList, setProjectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});

  const debounceTimeout = useRef(null);
  const showToast = useToast();



  const getAllProjects = async () => {
    try {
        const res = await getProjectByBusinessService(userDetail?.businessDetail?.businessId);
        let _projects = res.data.responseData.projectData || [];
        if(_projects.length > 0) {
            _projects = _projects.map(item => ({...item, label: item.projectName, value: item.id}))
            setProjectList(_projects);
        }
    } catch (error) {
        console.error('Error fetching projects:', error);
    }
};


useEffect(() => {
    if(userDetail?.businessDetail?.businessId && isGlobal){
        getAllProjects();
        setAddExpense(true);
    }
    
}, [isGlobal])





  const handleExpensesModalClose = () => setShowExpensesModal(false);
  const handleExpensesFilterClick = () => setShowExpensesFilter(true);
  const handleAddExpensesClick = () => {
    setAddExpense(true);
    setShowExpensesModal(false);
  };
  const handleAddExpensesClose = () => {
    setAddExpense(false);
    setActiveSubTab("myExpenses");
  };
  const handleExpensesReviewClick = (data) => {
    setExpenseReviewData(data);
    setReviewExpense(true);
  };
  const handleExpensesReviewClose = () => {
    setExpenseReviewData({});
    setReviewExpense(false);
    fetchExpensesList();
  };
  const handleViewExpensesClick = (data) => {
    setViewExpenseData(data);
    setViewExpense(true);
  };
  const handleViewExpensesClose = () => {
    setViewExpenseData({});
    setViewExpense(false);
  };
  const handleResubmitExpensesClick = (data) => {
    setResubmitExpenseData(data);
    setResubmitExpense(true);
    fetchExpensesList();
  };
  const handleResubmitExpensesClose = () => {
    setResubmitExpenseData({});
    setResubmitExpense(false);
    fetchExpensesList();
  };
  const handleOpenActionModal = (id, title, name) => {
    setExpenseId(id);
    setExpenseTitle(title);
    setExpenseButtons(name);
    setIsActionModalOpen(true);
  };
  const handleCloseActionModal = () => {
    setExpenseId(null);
    setExpenseTitle("");
    setExpenseButtons("");
    setIsActionModalOpen(false);
  };
  const handleNotShowAgain = (e) => {
    localStorage.setItem("showExpensesModal", e.target.checked);
    setNotShowAgain(e.target.checked);
  };
  const handleActiveSubTab = (tab) => {
    setActiveSubTab(tab);
    setSearchTitle("");
    setFilterStatus([
      ...contractStatus.map((item) => ({ ...item, isChecked: false })),
    ]);
  };
  const handleSearchTitle = (e) => {
    const value = e.target.value;
    setSearchTitle(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      applyFilters(value, filterStatus, activeSubTab);
    }, 500);
  };

  const applyFilters = (searchQuery, filters, tab) => {
    let filteredList;

    if (tab === "myExpenses") {
      filteredList = myExpensesList;
    } else {
      filteredList = expensesForReviewList;
    }

    const checkedFilters = filters
      .filter((filter) => filter.isChecked)
      .map((filter) => filter.id);

    if (checkedFilters.length > 0) {
      filteredList = filteredList.filter((item) =>
        checkedFilters.includes(item.reviewStatus)
      );
    }

    if (searchQuery) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      filteredList = filteredList.filter((item) =>
        Object.keys(item).some((key) => {
          let value = item[key];

          // Map the status field to its corresponding text if necessary
          if (key === "reviewStatus" && _contractStatus[value] != null) {
            value = _contractStatus[value].value;
          }

          return (
            value !== null &&
            value.toString().toLowerCase().includes(lowerCaseQuery)
          );
        })
      );
    }

    if (tab === "myExpenses") {
      setMyExpensesListFilter(filteredList || []);
    } else {
      setExpensesForReviewListFilter(filteredList || []);
    }
  };

  useEffect(() => {
    applyFilters(searchTitle, filterStatus, activeSubTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubTab, filterStatus]);

  useEffect(() => {
    if (activeTab === "Expenses") {
      const showExpensesModal =
        localStorage.getItem("showExpensesModal") || false;
      //setAddExpense(false);
      setActiveSubTab("myExpenses");
      if (showExpensesModal) {
        setShowExpensesModal(false);
      } else {
        setShowExpensesModal(true);
      }
      const projectDetail = localStorage.getItem("projectDetail")
        ? JSON.parse(localStorage.getItem("projectDetail"))
        : null;
      const authUserData = localStorage.getItem("authUserData")
        ? JSON.parse(localStorage.getItem("authUserData"))
        : null;
      setStaticDetails(projectDetail);
      setUserDetails(authUserData);
    }
  }, [activeTab]);



  useEffect(() => {
      fetchExpensesList();
  }, [selectedProject]);

  const fetchExpensesList = async () => {
    setIsLoading(true);
    setMyExpensesList([]);
    setMyExpensesListFilter([]);
    setExpensesForReviewList([]);
    setExpensesForReviewListFilter([]);
    try {
      const projectId = projectDetails?.id || '';
      const businessId = userDetail?.businessDetail?.businessId;
      let params = `?businessId=${businessId}`;
      if (!isGlobal || (isGlobal && selectedProject.value)) {
        const effectiveProjectId = isGlobal ? selectedProject.value : projectId;
        params += `&projectId=${effectiveProjectId}`;
      }
      const response = await getExpensesList(params);
      setMyExpensesList(response.data?.responseData?.myExpenses);
      setMyExpensesListFilter(response.data?.responseData?.myExpenses);
      setExpensesForReviewList(response.data?.responseData?.forReviews);
      setExpensesForReviewListFilter(response.data?.responseData?.forReviews);
    } catch (error) {
      console.error("Error fetching Expenses list:", error);
    }
    setIsLoading(false);
  };

  const handleAction = async (id, action) => {
    const token = localStorage.getItem("token");
    if (action === 1) {
      const formData = new FormData();
      formData.append("reviewStatus", 5);
      const response = await updateExpense(id, formData, token);
      if (response.data?.status === 1) {
        showToast("Expense Cancelled Successfully!", "success");
      } else {
        showToast("Expense Cancelled Failed!", "error");
      }
    }
    if (action === 2) {
      const response = await deleteExpenses(id);
      if (response.data?.status === 1) {
        showToast(response.data?.responseData?.message, "success");
      } else {
        showToast("Expense Deletion Failed!", "error");
      }
    }
    if (action === 6) {
      const formData = new FormData();
      formData.append("reviewStatus", 16);
      const response = await updateExpense(id, formData, token);
      if (response.data?.status === 1) {
        showToast("Expense Mark as Paid Successfully!", "success");
      } else {
        showToast("Expense Mark as Paid Failed!", "error");
      }
    }
    fetchExpensesList();
  };

  const handleRemindExpense = async (id) => {
    const data = { expenseId: id };
    const response = await remindExpenses(data);
    showToast(response.data?.responseData?.message, "success");
  };

  useEffect(() => {
    if (activeSubTab && staticDetails && staticDetails.id) {
      fetchExpensesList();
    }
  }, [activeSubTab, staticDetails]);

  if (addExpense) {
    return (
      <AddExpense
        isGlobal={isGlobal}
        handleCancel={handleAddExpensesClose}
        staticDetails={staticDetails}
        userDetails={userDetails}
      />
    );
  } else if (viewExpense) {
    return (
      <ViewExpense
        handleCancel={handleViewExpensesClose}
        viewExpenseData={viewExpenseData}
      />
    );
  } else if (resubmitExpense) {
    return (
      <ResubmitExpense
        handleCancel={handleResubmitExpensesClose}
        resubmitExpenseData={resubmitExpenseData}
      />
    );
  } else if (reviewExpense) {
    return (
      <ReviewExpense
        handleCancel={handleExpensesReviewClose}
        expenseReviewData={expenseReviewData}
      />
    );
  } else {
    return (
      <>
        <div className="dealroom_main_div">
          <div className="dealroom_detail_upper _flex_row_spacebtw_">
            <div className="dealroom_detail_upper_left _flex_gap_30">
            {isGlobal ?  <Select
                            classNamePrefix="cm_ui_select"
                            value={selectedProject.value ? selectedProject : ''}
                            onChange={e => setSelectedProject(e)}
                            options={[
                                {label: 'All Projects', value: ''},
                                ...projectsList
                            ]}
                            placeholder="All Projects" /> : ''}

              <div className="dashboard_upper_input ">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTitle}
                  onChange={handleSearchTitle}
                />
                <img src={searchIcon} alt="searchIcon" />
              </div>
              <CommonButton
                label="Filter"
                fontSize="14px"
                fontWeight="700"
                className="dash_common_btn white_bg_btn"
                icon={filterIcon}
                onClick={handleExpensesFilterClick}
              />
              {showExpensesFilter && (
                <ChangeRequestFilter
                  handleFilterClose={() => setShowExpensesFilter(false)}
                  filterBy={filterStatus}
                  setFilterBy={setFilterStatus}
                />
              )}
            </div>
            <div className="dealroom_detail_upper_right _flex_gap_30">
              <CommonButton
                label="Add Expense"
                fontSize="14px"
                fontWeight="700"
                className="dash_common_btn"
                icon={plusIcon}
                onClick={handleAddExpensesClick}
              />
            </div>
          </div>
          <h2 className="dashboard_heading">Expenses Details</h2>
          <p className="font_14">
            Reminders for expenses will be sent every 2 days until paid.
          </p>
          {userDetails &&
          userDetails.role &&
          (userDetails.role === "Owner" ||
            userDetails.role === "Accountant") ? (
            <Tabs
              id="controlled-tab-example"
              className="mt-3 mb-3"
              activeKey={activeSubTab}
              onSelect={handleActiveSubTab}
            >
              <Tab eventKey="myExpenses" title="My Expenses">
                <div
                  style={{
                    maxHeight: "450px",
                    overflowY: "auto",
                    marginTop: "40px",
                  }}
                >
                  <table
                    className="project_table dealroom_table"
                    style={{ marginTop: 0 }}
                  >
                    <thead style={{ position: "sticky", top: -1, zIndex: 1 }}>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Title</th>
                        <th>Amount</th>
                        <th>Added By</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td
                            colSpan={6}
                            style={{
                              fontSize: "16px",
                              fontWeight: 700,
                              textAlign: "center",
                            }}
                          >
                            <Loader type="2" />
                          </td>
                        </tr>
                      ) : myExpensesListFilter?.length > 0 ? (
                        myExpensesListFilter.map((myExpense, i) => {
                          const statusObject = contractStatus.find(
                            (status) => status.id === myExpense.reviewStatus
                          );
                          return (
                            <tr
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (
                                  statusObject &&
                                  statusObject.value !== "Rejected"
                                ) {
                                  handleViewExpensesClick(myExpense);
                                }
                              }}
                            >
                              <td
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ margin: "0 23px" }}>
                                  <img
                                    src={threeDotIcon}
                                    alt="action plan"
                                    width={15}
                                    height={15}
                                    style={{
                                      marginRight: 10,
                                      cursor: "pointer",
                                      transform: "rotate(90deg)",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleOpenActionModal(
                                        myExpense?.id,
                                        myExpense?.title,
                                        "ownerExpense"
                                      );
                                    }}
                                  />
                                  {statusObject &&
                                  statusObject.value === "Rejected" ? (
                                    <CustomTooltip text="Re-Submit">
                                      <img
                                        src={editIcon}
                                        alt="edit plan"
                                        style={{
                                          marginLeft: 10,
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleResubmitExpensesClick(
                                            myExpense
                                          );
                                        }}
                                      />
                                    </CustomTooltip>
                                  ) : (
                                    <img
                                      src={editBlackIcon}
                                      alt="edit plan"
                                      style={{ marginLeft: 10 }}
                                    />
                                  )}
                                </span>
                                <span style={{ marginTop: 3 }}>L-{i + 1}</span>
                              </td>
                              <td>{dateRender(myExpense?.expenseDate)}</td>
                              <td className="dealroom_table_title">
                                {myExpense?.title}
                              </td>
                              <td>{formatAmountInINR(myExpense?.amount)}</td>
                              <td>{myExpense?.createBy || "N/A"}</td>
                              <td>
                                <p
                                  className="phases"
                                  style={{
                                    backgroundColor: statusObject.color,
                                  }}
                                >
                                  {statusObject.value}
                                </p>
                              </td>
                              <td className="dealroom_table_title">
                                <span
                                  style={{
                                    color: "#0d6efd",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemindExpense(myExpense?.id);
                                  }}
                                >
                                  Remind
                                </span>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={7} style={{ textAlign: "center" }}>
                            My Expenses Not Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Tab>
              <Tab eventKey="forReview" title="For Review">
                <div
                  style={{
                    maxHeight: "450px",
                    overflowY: "auto",
                    marginTop: "40px",
                  }}
                >
                  <table
                    className="project_table dealroom_table"
                    style={{ marginTop: 0 }}
                  >
                    <thead style={{ position: "sticky", top: -1, zIndex: 1 }}>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Title</th>
                        <th>Amount</th>
                        <th>Added By</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td
                            colSpan={6}
                            style={{
                              fontSize: "16px",
                              fontWeight: 700,
                              textAlign: "center",
                            }}
                          >
                            <Loader type="2" />
                          </td>
                        </tr>
                      ) : expensesForReviewListFilter?.length > 0 ? (
                        expensesForReviewListFilter.map(
                          (expenseForReview, i) => {
                            const statusObject = contractStatus.find(
                              (status) =>
                                status.id === expenseForReview.reviewStatus
                            );
                            return (
                              <tr
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (
                                    statusObject &&
                                    statusObject.value !== "Submitted" &&
                                    statusObject.value !== "Draft"
                                  ) {
                                    handleViewExpensesClick(expenseForReview);
                                  }
                                }}
                              >
                                <td
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                  }}
                                >
                                  <span style={{ margin: "0 33px" }}>
                                    <img
                                      src={threeDotIcon}
                                      alt="action plan"
                                      width={15}
                                      height={15}
                                      style={{
                                        marginRight: 10,
                                        cursor: "pointer",
                                        transform: "rotate(90deg)",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleOpenActionModal(
                                          expenseForReview?.id,
                                          expenseForReview?.title,
                                          "reviewExpense"
                                        );
                                      }}
                                    />
                                    {statusObject &&
                                    (statusObject.value === "Submitted" ||
                                      statusObject.value === "Draft") ? (
                                      <CustomTooltip text="Review">
                                        <img
                                          src={editIcon}
                                          alt="edit plan"
                                          style={{
                                            marginLeft: 10,
                                            cursor: "pointer",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleExpensesReviewClick(
                                              expenseForReview
                                            );
                                          }}
                                        />
                                      </CustomTooltip>
                                    ) : (
                                      <img
                                        src={editBlackIcon}
                                        alt="edit plan"
                                        style={{ marginLeft: 10 }}
                                      />
                                    )}
                                  </span>
                                  <span style={{ marginTop: 3 }}>
                                    L-{i + 1}
                                  </span>
                                </td>
                                <td>
                                  {dateRender(expenseForReview?.expenseDate)}
                                </td>
                                <td className="dealroom_table_title">
                                  {expenseForReview?.title}
                                </td>
                                <td>
                                  {formatAmountInINR(expenseForReview?.amount)}
                                </td>
                                <td>{expenseForReview?.createBy || "N/A"}</td>
                                <td>
                                  <p
                                    className="phases"
                                    style={{
                                      backgroundColor: statusObject.color,
                                    }}
                                  >
                                    {statusObject.value}
                                  </p>
                                </td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan={6} style={{ textAlign: "center" }}>
                            No Expenses Found For Review
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Tab>
            </Tabs>
          ) : (
            <div
              style={{
                maxHeight: "450px",
                overflowY: "auto",
                marginTop: "40px",
              }}
            >
              <table
                className="project_table dealroom_table"
                style={{ marginTop: 0 }}
              >
                <thead style={{ position: "sticky", top: -1, zIndex: 1 }}>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Title</th>
                    <th>Amount</th>
                    <th>Added By</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td
                        colSpan={6}
                        style={{
                          fontSize: "16px",
                          fontWeight: 700,
                          textAlign: "center",
                        }}
                      >
                        <Loader type="2" />
                      </td>
                    </tr>
                  ) : myExpensesListFilter?.length > 0 ? (
                    myExpensesListFilter.map((myExpense, i) => {
                      const statusObject = contractStatus.find(
                        (status) => status.id === myExpense.reviewStatus
                      );
                      return (
                        <tr
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (
                              statusObject &&
                              statusObject.value !== "Rejected"
                            ) {
                              handleViewExpensesClick(myExpense);
                            }
                          }}
                        >
                          <td
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ margin: "0 23px" }}>
                              <img
                                src={threeDotIcon}
                                alt="action plan"
                                width={15}
                                height={15}
                                style={{
                                  marginRight: 10,
                                  cursor: "pointer",
                                  transform: "rotate(90deg)",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleOpenActionModal(
                                    myExpense?.id,
                                    myExpense?.title,
                                    "userExpense"
                                  );
                                }}
                              />
                              {statusObject &&
                              statusObject.value === "Rejected" ? (
                                <CustomTooltip text="Re-Submit">
                                  <img
                                    src={editIcon}
                                    alt="edit plan"
                                    style={{
                                      marginLeft: 10,
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleResubmitExpensesClick(myExpense);
                                    }}
                                  />
                                </CustomTooltip>
                              ) : (
                                <img
                                  src={editBlackIcon}
                                  alt="edit plan"
                                  style={{ marginLeft: 10 }}
                                />
                              )}
                            </span>
                            <span style={{ marginTop: 3 }}>L-{i + 1}</span>
                          </td>
                          <td>{dateRender(myExpense?.expenseDate)}</td>
                          <td className="dealroom_table_title">
                            {myExpense?.title}
                          </td>
                          <td>{formatAmountInINR(myExpense?.amount)}</td>
                          <td>{myExpense?.createBy || "N/A"}</td>
                          <td>
                            <p
                              className="phases"
                              style={{
                                backgroundColor: statusObject.color,
                              }}
                            >
                              {statusObject.value}
                            </p>
                          </td>
                          <td className="dealroom_table_title">
                            <span
                              style={{
                                color: "#0d6efd",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemindExpense(myExpense?.id);
                              }}
                            >
                              Remind
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} style={{ textAlign: "center" }}>
                        Expenses Not Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <Modal
          show={showExpensesModal}
          onHide={handleExpensesModalClose}
          className="dealroom_intital_modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img src={expensesIcon} alt="expensesIcon" />
            <h3>Expenses</h3>
            <p className="font_16">Track all your expenses for this project</p>
            <div
              className="dealroom_intital_modal_div"
              style={{ justifyContent: "center" }}
            >
              <CommonButton
                label="Add New Expense"
                fontSize="14px"
                fontWeight="700"
                className="dash_common_btn"
                icon={false}
                width={"52%"}
                onClick={handleAddExpensesClick}
              />
            </div>
            <h6 className="font_14">
              <input
                type="checkbox"
                value={notShowAgain}
                onClick={handleNotShowAgain}
              />
              Don’t show this again
            </h6>
          </Modal.Body>
        </Modal>

        {isActionModalOpen && (
          <ActionModal
            isOpen={isActionModalOpen}
            title={expenseTitle}
            onClose={handleCloseActionModal}
            onSave={handleAction}
            dealRoomID={expenseId}
            pdf={""}
            buttons={expenseButtons}
          />
        )}
      </>
    );
  }
};

export default Expenses;
