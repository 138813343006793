import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import rightarrow from "./../assets/logos/right_arrow.svg";
import crossicon from "./../assets/dashboard/close.svg";
import plusicon from "./../assets/dashboard/pluscircle.svg";
import PhoneNumber from "../components/PhoneNumber";
import useValidation from "../utils/customHooks/useValidations";
import { separateCountryCodeAndNumber } from "../utils/helper";
import {
  addProjectService,
  getClientbyBusinessIdService,
  updateClientService,
} from "../services";
import useToast from "../utils/customHooks/useToast";

const ClientDetails = ({
  onHide,
  openProject,
  from,
  callProjectList,
  _refreshPipeLine,
  type = "",
  id = null,
  updateProjectList = null
}) => {
  const [allClientsName, setAllClientsName] = useState([]);
  const [allClientsDetails, setAllClientDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [tagError, setTagError] = useState("");
  const [colorError, setColorError] = useState("");

  const [clientDetails, setClientDetails] = useState({
    clientName: "",
    firstName: "",
    lastName: "",
    email: "",
    country_code: "",
    phone_number: "",
    status: "1",
    clientTag: [],
    address: "",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagName, setTagName] = useState("");
  const [tagColor, setTagColor] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [isOptionLoading, setIsOptionLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [preFilledNumberItems, setPreFilledNumberItems] = useState({
    _cc: "",
    _number: "",
  });
  const [clientNameError, setClientNameError] = useState("");
  const showToast = useToast();



  useEffect(() => {
    getAllClients();
    if (type === "update") {
      let _projectDetail = JSON.parse(localStorage.getItem("projectDetail"));
      if (_projectDetail) {
        let temp = { ...clientDetails };
        temp.clientName = _projectDetail.clients[0].clientName;
        temp.firstName = _projectDetail.clients[0].firstName;
        temp.lastName = _projectDetail.clients[0].lastName;
        temp.country_code = _projectDetail.clients[0].country_code;
        temp.phone_number = _projectDetail.clients[0].phone_number;
        temp.address = _projectDetail.clients[0].address;
        temp.email = _projectDetail.clients[0].email;
        setClientDetails(temp);
        setFirstName(temp.firstName);
        setlastName(temp.lastName);
        setEmail(temp.email);
        setAddress(temp.address);
        if (temp.country_code && temp.phone_number) {
          let _temp = { ...preFilledNumberItems };
          _temp._cc = temp.country_code;
          _temp._number = temp.phone_number;
          setPreFilledNumberItems(_temp);
        }
        if (
          _projectDetail.clients[0].country_code &&
          _projectDetail.clients[0].phone_number
        ) {
          let uniqueNumber = `${_projectDetail.clients[0].country_code} ${_projectDetail.clients[0].phone_number}`;
          setPhoneNumber(uniqueNumber);
        }
        setSelectedOption({
          label: _projectDetail.clients[0].clientName.replace(/^\w/, (c) =>
            c.toUpperCase()
          ),
          value: _projectDetail.clients[0].clientName.replace(/^\w/, (c) =>
            c.toUpperCase()
          ),
        });
      }
    }
  }, []);

  useEffect(() => {
    if (
      preFilledNumberItems._cc !== "" &&
      preFilledNumberItems._number !== ""
    ) {
      let temp = {
        _cc: "",
        _number: "",
      };
      setPreFilledNumberItems(temp);
    }
  }, [phoneNumber]);

  // useEffect(() => {
  //   if (selectedOption !== null) {
  //     setClientNameError("");
  //     setClientDetails({
  //       ...clientDetails,
  //       clientName: selectedOption?.value.replace(/^\w/, (c) =>
  //         c.toUpperCase()
  //       ),
  //     });
  //   } else {
  //     setClientDetails({
  //       ...clientDetails,
  //       clientName: "",
  //     });
  //   }
  // }, [selectedOption]);

  const getAllClients = async () => {
    let businessDetails = JSON.parse(localStorage.getItem("authUserData")) || null;
    if (businessDetails) {
      setIsOptionLoading(true);
      let businessId = businessDetails?.businessDetail?.businessId || null;
      if (businessId) {
        try {
          const res = await getClientbyBusinessIdService(businessId);
          if (res.data.status === 1) {
            const uniqueClients = [];
            const clientNames = new Set();
            
            // Collect unique clients
            res.data.responseData.clients.forEach(client => {
              const lowerCaseName = client.clientName.toLowerCase();
              if (!clientNames.has(lowerCaseName)) {
                clientNames.add(lowerCaseName);
                uniqueClients.push(client);
              }
            });
  
            // Sort unique clients by first name
            uniqueClients.sort((a, b) => {
              const firstNameA = a.clientName.split(" ")[0].toLowerCase();
              const firstNameB = b.clientName.split(" ")[0].toLowerCase();
              return firstNameA.localeCompare(firstNameB);
            });
  
            setAllClientDetails(uniqueClients);
            const clientData = uniqueClients.map(client => ({
              value: client.clientName,
              label: client.clientName,
            }));
            setAllClientsName(clientData);
          }
        } catch (error) {
          console.error("Error fetching clients:", error);
        } finally {
          setIsOptionLoading(false);
        }
      }
    }
  };
  

  const handleCreateOption = (inputValue) => {
    setIsOptionLoading(true);
    setTimeout(() => {
      const newOption = { label: inputValue, value: inputValue };
      setIsOptionLoading(false);
      setAllClientsName((prev) => [...prev, newOption]);
      setSelectedOption(newOption); // Update the selected option here
    }, 1000);
  };

  const {
    errors,
    setErrors,
    validateForm,
    runTimeErrors,
    setRunTimeErrors,
    validateRunTimeError,
  } = useValidation();

  const addTag = (e) => {
    e.preventDefault();
    let hasError = false;

    if (!tagName) {
      setTagError("Please enter a tag name.");
      hasError = true;
    } else {
      setTagError("");
    }

    if (!selectedColor) {
      setColorError("Please select a color.");
      hasError = true;
    } else {
      setColorError("");
    }

    if (!hasError) {
      setTags([...tags, { name: tagName, color: tagColor }]);
      setTagName("");
      setTagColor("");
      setSelectedColor("");
      document.getElementById("addTagForm").style.display = "none";
    }
  };

  const deleteTag = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const handleColorSelect = (color) => {
    setTagColor(color);
    setSelectedColor(color);
    setColorError(''); 

  };

  const handleTagNameChange = (e) => {
    const newTagName = e.target.value.replace(/^\w/, (c) => c.toUpperCase());
    setTagName(newTagName);
    if (newTagName === "") {
      setTagError("Please enter a tag name.");
    } else {
      setTagError("");
    }
  };


  const handleCancel = () => {
    onHide();
    openProject();
  };

  const handleOnChange = (fieldName, e) => {
    setClientDetails({
      ...clientDetails,
      [fieldName]: e.target.value,
    });
    setErrors({
      ...errors,
      [fieldName]: "",
    });
    setRunTimeErrors({
      ...runTimeErrors,
      [fieldName]: "",
    });
    validateRunTimeError(fieldName, e.target.value);
  };

  const handleKeyDown = (e) => {
    if (/[^A-Za-z]\s/.test(e.key)) {
      e.preventDefault(); // Prevent input of numeric and special characters
    }
  };

  const validatePhone = (no) => {
    if (no === "") return false;
    else return true;
  };

  const handleAdd = () => {
    setIsSubmit(true);
    if (type !== "update") {
      let projectDetails = JSON.parse(localStorage.getItem("ProjectDetails")) || {};
      const { cc, number } = separateCountryCodeAndNumber(phoneNumber);
      let userData = JSON.parse(localStorage.getItem("authUserData"));
      if (userData) {
        let _businessID = userData?.businessDetail?.businessId;
        projectDetails["businessId"] = _businessID;
      }
      const data = { ...clientDetails };
      data.country_code = cc;
      data.phone_number = number;

      if (tags.length > 0) {
        data.clientTag = [...tags];
      }

      if (cc && number) {
        setClientDetails((prevState) => ({
          ...clientDetails,
          country_code: cc,
          phone_number: Number(number),
        }));
      }

      if (data.firstName) {
        data.clientName = `${data.firstName} ${data.lastName}`
      }

      if (projectDetails && projectDetails?.clients) {
        projectDetails.clients.push(data);
      } else {
        projectDetails["clients"] = [];
        projectDetails.clients.push(data);
      }

      if (
        clientNameError === "" &&
        validateForm(clientDetails, "clientDetails") &&
        validatePhone(phoneNumber) === true &&
        phoneError === ""
      ) {


        setIsLoading(true);
        try {
          addProjectService(projectDetails).then((res) => {
            if (res.statusCode === 200) {
              if (res.data.status === 1) {
                localStorage.removeItem("ProjectDetails");
                onHide();
                if (from === "projectDetails") {
                  callProjectList();
                }
                _refreshPipeLine();
                setIsLoading(false);
              }
            }
          });
        } catch (error) {
          setIsLoading(false);
        }
      }
    } else {
      let _projectDetail = JSON.parse(localStorage.getItem("projectDetail"));
      let details = {};
      details["firstName"] = firstName || clientDetails.firstName;
      details["lastName"] = lastName || clientDetails.lastName;
      details["email"] = email || clientDetails.email;
      if (
        clientNameError === "" &&
        clientDetails.clientName !== "" &&
        validateForm(details, "clientDetails") &&
        validatePhone(phoneNumber) === true &&
        phoneError === ""
      ) {
        setIsLoading(true);
        try {
          if (_projectDetail) {
            const { _cc, _number } = preFilledNumberItems;
            if (_cc === "" && _number === "") {
              const { cc, number } = separateCountryCodeAndNumber(phoneNumber);
              clientDetails.country_code = cc;
              clientDetails.phone_number = number;
            } else {
              clientDetails.country_code = _cc;
              clientDetails.phone_number = _number;
            }

            let temp = { ...clientDetails };

            if (id !== null) {
              _projectDetail.id = id;
            }

            _projectDetail.clients[0].clientName = clientDetails.clientName;
            _projectDetail.clients[0].email = clientDetails.email || email;
            _projectDetail.clients[0].firstName = clientDetails.firstName || firstName;
            _projectDetail.clients[0].lastName = clientDetails.lastName || lastName;
            _projectDetail.clients[0].country_code = clientDetails.country_code || preFilledNumberItems._cc;
            _projectDetail.clients[0].phone_number = clientDetails.phone_number || preFilledNumberItems._number;
            _projectDetail.clients[0].address = address || clientDetails.address;
          }

          const matchedClient = allClientsDetails.find(client => client.clientName.toLowerCase() === selectedOption?.value.toLowerCase());
          let clientID
          // if(matchedClient){
          //   clientID = matchedClient.id
          // }else{
          //   clientID = 0
          // }

          let data = {
            clientName: `${clientDetails.firstName || firstName} ${clientDetails.lastName || lastName}`,
            firstName: clientDetails.firstName || firstName,
            lastName: clientDetails.lastName || lastName,
            country_code: clientDetails.country_code || preFilledNumberItems._cc,
            phone_number: clientDetails.phone_number || preFilledNumberItems._number,
            address: address || clientDetails.address,
            email: clientDetails.email || email,
          }
          updateClientService(id, clientDetails.id || 0, data).then((res) => {
            if (res.statusCode === 200) {
              if (res.data.status === 1) {
                setIsLoading(false)
                _projectDetail["id"] = id;
                localStorage.setItem("projectDetail", JSON.stringify(_projectDetail));
                updateProjectList()
                onHide();
                _refreshPipeLine(_projectDetail);
                showToast("Client details updated successfully", "success");
              } else {
                setIsLoading(false)
              }
            }
          })
        } catch (error) {
          setIsLoading(false);
        }
      }
    }
  };

  const handleClienNameChange = (e) => {
    if (e.length > 20) {
      e.preventDefault();
    }
  };

  const handleSelectKeyDown = (e) => {
    const input = e.target;
    if (input.value.length >= 20 && !/[^A-Za-z\s.]/.test(e.key)) {
      e.preventDefault();
    } else if (/[^A-Za-z\s.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleSelectChange = (newValue) => {
    
    let searchedClientName = newValue?.value.trim();
    if (searchedClientName) {
      const matchedClient = allClientsDetails.find(client => client.clientName.toLowerCase() === searchedClientName.toLowerCase());
      if (matchedClient) {
          let temp = {...clientDetails}
          temp.firstName = matchedClient?.firstName || ''
          temp.lastName = matchedClient?.lastName || ''
          temp.email = matchedClient?.email || ''
          temp.email = matchedClient?.email || ''
          temp.country_code = matchedClient?.country_code || ''
          temp.phone_number = matchedClient?.phone_number || ''
          temp.clientTag = 1
          temp.clientTag = matchedClient?.clientTag
          temp.address = matchedClient?.address
          temp.id = matchedClient?.id
  
          if(matchedClient?.country_code && matchedClient?.phone_number){
            let uniqueNumber = `${matchedClient?.country_code} ${matchedClient?.phone_number}`;
            setPhoneNumber(uniqueNumber);
          }else{
            setPhoneNumber('');
          }

          setClientDetails(temp)
      }
    }
  };

  return (
    <Modal show={true} onHide={onHide} backdrop="static">
      <div className="modal_main_div">
        <h2 className="modal_heading">
          {type !== "update" ? "Client Details" : "Update Client Details"}
        </h2>
        <ul className="modal_main_ul">
          <li className="modal_main_li">
            <label className="font_14">Add Existing Client</label>
            <CreatableSelect
              isClearable
              isDisabled={isOptionLoading}
              isLoading={isOptionLoading}
              onChange={(newValue) => { setSelectedOption(newValue); handleSelectChange(newValue); }}
              onCreateOption={handleCreateOption}
              options={allClientsName}
              value={selectedOption}
              onInputChange={(e) => handleClienNameChange(e)}
              onKeyDown={handleSelectKeyDown} />

            <div className="_error_">{clientNameError}</div>
          </li>

          <li className="line_or"><span>Or</span></li>

          
          <li className="modal_main_li">
            <div className="modal_two_field">
              <div className="modal_two_field_left">
                <label className="font_14">
                  First Name<span className="_imp_">*</span>
                </label>
                <input
                  type="text"
                  value={clientDetails.firstName || firstName}
                  placeholder="First Name"
                  maxLength={50}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    handleOnChange("firstName", e);
                    setFirstName(e.target.value);
                  }}
                />
                <div className="_error_">
                  {errors?.firstName || runTimeErrors?.firstName}
                </div>
              </div>
              <div className="modal_two_field_left">
                <label className="font_14">
                  Last Name<span className="_imp_">*</span>
                </label>
                <input
                  type="text"
                  value={clientDetails.lastName || lastName}
                  placeholder="Last Name"
                  maxLength={50}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    handleOnChange("lastName", e);
                    setlastName(e.target.value);
                  }}
                />
                <div className="_error_">
                  {errors?.lastName || runTimeErrors?.lastName}
                </div>
              </div>
            </div>
          </li>
          <li className="modal_main_li">
            <label className="font_14">
              Email<span className="_imp_">*</span>
            </label>
            <input
              type="email"
              placeholder="xyz@gmail.com"
              value={clientDetails.email || email}
              maxLength={50}
              onChange={(e) => {
                handleOnChange("email", e);
                setEmail(e.target.value);
              }}
            />
            <div className="_error_">
              {errors?.email || runTimeErrors?.email}
            </div>
          </li>
          <li className="" style={{ marginBottom: "16px" }}>
            <div className="_label_ ">
              Mobile Number<span className="_imp_">*</span>
            </div>
            <PhoneNumber
              value={phoneNumber}
              setValue={setPhoneNumber}
              isError={phoneError ? true : false}
              setError={() => { return}}
              isSubmitTouch={isSubmit}
            />
            {phoneError && <span className="_error_">{phoneError}</span>}
          </li>
          <li className="modal_main_li">
            <label className="font_14">
              Address <span>(optional)</span>
            </label>
            <input
              type="text"
              placeholder="Address"
              value={clientDetails.address || address}
              onChange={(e) => {
                setClientDetails({ ...clientDetails, address: e.target.value });
                setAddress(e.target.value);
              }}
            />
          </li>

          {type !== "update" ? (
            <>
              <li className="modal_main_li">
                <label className="font_14">
                  Client Tags <span>( Not visible to the client )</span>
                </label>
                <div className="client_tag_div">
                  {tags.map((tag, index) => (
                    <span key={index} style={{ backgroundColor: tag.color }}>
                      {tag.name}
                      <img
                        src={crossicon}
                        alt="Delete"
                        onClick={() => deleteTag(index)}
                      />
                    </span>
                  ))}
                  <img
                    src={plusicon}
                    alt="Add"
                    onClick={() =>
                      (document.getElementById("addTagForm").style.display =
                        "block")
                    }
                  />
                </div>
              </li>

              <form
                id="addTagForm"
                className="modal_main_li"
                style={{ display: "none" }}
                onSubmit={addTag}>
        
                <input type="text" placeholder="Name Tag" value={tagName} onChange={handleTagNameChange} maxLength={20} />

                {tagError && (
                  <div className="error-message _error_">{tagError}</div>
                )}

                <div className="select_color_div">
                  <label className="font_14 select_color_label">
                    Select Color :
                  </label>
                  <div className="color-selector">
                    {[
                      "#EF4444",
                      "#F97316",
                      "#FACC15",
                      "#4ADE80",
                      "#2DD4BF",
                      "#3B82F6",
                      "#6366F1",
                      "#EC4899",
                      "#F43F5E",
                      "#D946EF",
                      "#8B5CF6",
                      "#0EA5E9",
                      "#10B981",
                      "#84CC16",
                    ].map((color) => (
                      <div className="modal_selector_div" key={color}>
                        <button
                          type="button"
                          style={{ backgroundColor: color }}
                          className={selectedColor === color ? "active" : ""}
                          onClick={() => handleColorSelect(color)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                {colorError && (
                  <div className="error-message _error_">{colorError}</div>
                )}

                <button type="submit" className="create_modal_btn">
                  Create
                </button>
              </form>
            </>
          ) : (
            ""
          )}
        </ul>
      </div>
      <div className="modal_btn_div modal_color_btn_div">
        <button
          className="_btn_transparent_ modal_div_button"
          onClick={handleCancel}
        >
          <span>Cancel</span>
        </button>
        <button
          className={`_btn_normal_ modal_div_button ${
            isLoading ? "_disable_" : ""
          }`}
          onClick={handleAdd}
        >
          <span>{type !== "update" ? "ADD" : "UPDATE"}</span>
          <span className="_right_btn_icon_">
            <img src={rightarrow} alt="arrow" />
          </span>
        </button>
      </div>
    </Modal>
  );
};

export default ClientDetails;
