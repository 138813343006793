import { useDispatch } from "react-redux";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import GlobalSearch from "../../components/common/GlobalSearch";
import { logout } from "../../features/auth/authSlice";
import { persistor } from "../../store";
import InvoicesAmountChip from "./InvoicesAmountChip";
import InvoicesPieCharts from "./InvoicesPieCharts";
import InvoicesList from "./InvoicesList";
import plus from "../../assets/dashboard/plus.svg";
import { getProjectByBusinessService, getInvoiceOverviewService, getInvoiceListService } from "../../services";
import { dateFilterOptions } from "../../utils/constant";
import { formatDateRange } from "../../utils/helper";
import { Modal } from "react-bootstrap";


function currentDateFormat() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
}

function getPastDate(days) {
    const date = new Date();
    date.setDate(date.getDate() - days);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
}

function getFirstDateOfCurrentMonth() {
    const date = new Date();
    date.setDate(1); // Set to the 1st day of the month

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
}

function getFirstDateOfCurrentYear() {
    const date = new Date();
    date.setMonth(0); // Set to January (0-based index)
    date.setDate(1); // Set to the 1st day of the month

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
}



const Invoices = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let userDetail = JSON.parse(localStorage.getItem("authUserData")) || null;

    const [projectsList, setProjectList] = useState([]);
    const [selectedProject, setSelectedProject] = useState({});
    const [invoiceOverview, setInvoiceOverview] = useState({});
    const [invoiceList, setinvoiceList] = useState([]);
    const [selectedDateType, setSelectedDateType] = useState({label: '30 Days', value: 1});
    const [startDate, setStartDate] = useState('');
    const [selectedProjectErr, setSelectedProjectErr] = useState('');
    const [showProjectListModal, setShowProjectListModal] = useState(false);


    useEffect(() => {
        if(selectedDateType.value == 4){
            return
        }
        let _startDate = '';

        if(selectedDateType.value == 1){
            _startDate = getPastDate(30);
            
        }

        if(selectedDateType.value == 2){
            _startDate = getPastDate(60);
        }

        if(selectedDateType.value == 3){
            _startDate = getPastDate(90);
        }

        if(selectedDateType.value == 5){
            _startDate = getFirstDateOfCurrentMonth();
        }

        if(selectedDateType.value == 6){
            _startDate = getFirstDateOfCurrentYear();
        
        }
        setStartDate(_startDate);
    }, [selectedDateType])


    const handleLogout = () => {
        localStorage.clear();
        dispatch(logout());
        persistor.purge()
        navigate("/signin");
    };


    const getAllProjects = async () => {
        try {
            const res = await getProjectByBusinessService(userDetail?.businessDetail?.businessId);
            let _projects = res.data.responseData.projectData || [];
            if(_projects.length > 0) {
                _projects = _projects.map(item => ({...item, label: item.projectName, value: item.id}))
                setProjectList(_projects);
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };


    useEffect(() => {
        if(userDetail?.businessDetail?.businessId){
            getAllProjects();
        }
        
    }, [])




    const getInvoiceOverview = async () => {
        try {
            let query = `?businessId=${userDetail?.businessDetail?.businessId}`;
            if (selectedProject.value) {
                query += `&projectId=${selectedProject.value}`;
            }

            if (startDate) {
                query += `&startDate=${startDate}&&endDate=${currentDateFormat()}`;
            }

            const token = localStorage.getItem("token");
            const res = await getInvoiceOverviewService(query, token);
            if(res.data.status){
                setInvoiceOverview(res.data.responseData.invoiceOverview);
            }
        } catch (error) {
            console.error('Error fetching invoice overview:', error);
        }
    };


    const getInvoiceList = async () => {
        try {
            let query = `?businessId=${userDetail?.businessDetail?.businessId}`;
            if (selectedProject.value) {
                query += `&projectId=${selectedProject.value}`;
            }

            if (startDate) {
                query += `&startDate=${startDate}&&endDate=${currentDateFormat()}`;
            }

            const token = localStorage.getItem("token");
            const res = await getInvoiceListService(query, token);
            if(res.data.status){
                setinvoiceList(res.data.responseData.data);
            }
        } catch (error) {
            console.error('Error fetching invoice overview:', error);
        }
    };


    useEffect(() => {
        if(userDetail?.businessDetail?.businessId){
            getInvoiceOverview();
            getInvoiceList();
        }
        
    }, [selectedProject, startDate])



    const handleSelectProject = () => {
        if(selectedProject?.value){
          localStorage.setItem('projectDetail', JSON.stringify(selectedProject))
          navigate("/projectdetails");
          localStorage.setItem('isFromInvoicesModule', true)
          setShowProjectListModal(false)
        }else{
          setSelectedProjectErr('Select a project')
        }
      }
    


    return (
        <>


    <Modal show={showProjectListModal} onHide={() => setShowProjectListModal(false)} className="dealroom_intital_modal">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="p-5 pt-0 text-start">
            <h5 className="mb-5">Select project for which you want to create a dealroom doument</h5>
            <Select
              classNamePrefix="cm_ui_select "
              value={selectedProject.value ? selectedProject : ''}
              onChange={e => (setSelectedProject(e), setSelectedProjectErr(''))}
              options={[
                  ...projectsList
              ]}
            placeholder="Select project" /> 

            {selectedProjectErr ? <h6 className="res_err">{selectedProjectErr}</h6> : ''}

            <div className="justify-content-between align-items-center d-flex mt-5">
              <span onClick={() => setShowProjectListModal(false)} className="btn btn-dark px-4">Cancel</span>
              <span onClick={handleSelectProject} className="btn btn-primary px-4">Create</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <div className="dashboard_upper projectdetails_dash_upper">

        <div className="projectdetails_dash_left">
            <h3>{userDetail?.businessDetail?.businessName}</h3>
        </div>
        <div className="projectdetails_dash_left">
        
            <GlobalSearch />
            <div className="logout_main_div">
                <div className="name_intials_dash_upper">
                    {userDetail.firstName ? userDetail.firstName.charAt(0).toUpperCase() : ''}{userDetail.lastName ? userDetail.lastName.charAt(0).toUpperCase() : ''}
                </div>
                <div className="logout_div">
                <p onClick={handleLogout}>Logout</p>
                </div>
            </div>
        </div>
        
    </div>



    

            <div className="page_wrapper">
                <div className="d-flex align-items-center justify-content-between">
                   <div>
                        <h3 className="section_heading">Invoices</h3>
                        <p>For {formatDateRange(startDate)}</p>
                   </div>

                   <div>
                        <span onClick={() => setShowProjectListModal(true)} className="btn btn-primary"><img src={plus} alt='icon' /> Create New Invoice</span>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-3 mt-4">
                        <Select
                            classNamePrefix="cm_ui_select"
                            value={selectedProject.value ? selectedProject : ''}
                            onChange={e => setSelectedProject(e)}
                            options={[
                                {label: 'All Projects', value: ''},
                                ...projectsList
                            ]}
                            placeholder="All Projects" />
             
                    </div>
                    <div className="col-md-3 mt-4">
                        <Select
                            value={selectedDateType.value ? selectedDateType : ''}
                            onChange={e => setSelectedDateType(e)}
                            classNamePrefix="cm_ui_select"
                            placeholder="Select Dates Filter"
                            options={dateFilterOptions} />
             
                    </div>
                </div>
                            


                <InvoicesAmountChip data={invoiceOverview} viewType={1}  />
                {invoiceOverview?.invoiceChart ? <InvoicesPieCharts data={invoiceOverview.invoiceChart}  dateStr={formatDateRange(startDate)}/> : ''}
                <InvoicesList list={invoiceList} />

                
            </div>
  
        
      </>
    )
}

export default Invoices;




