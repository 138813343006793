import React, { Suspense } from "react";
import { ROUTES } from './utils/constant';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css'
import _nav from './_nav';
import NotFound from './pages/NotFound';
import RedirectPage from './pages/RedirectPage'
import ProtectedRoutes from './routes/ProtectedRoutes';
import PublicRoutes from "./routes/PublicRoutes";
import HtmlPage from "./components/HtmlPage";
import Terms from "./components/Terms";
import Policy from "./components/Policy";
import ContactUs from "./components/ContactUs";
import ClientSignatureTemplate from "./pages/ClientSignatureTemplate";
import ClientViewSignatureTemplate from "./pages/ClientViewSignatureTemplate";
import PaymentSuccess from "./pages/server-redirect/PaymentSuccess.js";

const Home = React.lazy(() => import('./pages/home/Home.jsx'))
const InteriorDesigner = React.lazy(() => import('./pages/home/industry/interiorDesigner.jsx'))
const Photographer = React.lazy(() => import('./pages/home/industry/photographer.jsx'))
const EventPlanner = React.lazy(() => import('./pages/home/industry/eventPlanner.jsx'))
const Architecture = React.lazy(() => import('./pages/home/industry/architect.jsx'))
const BookKeepers = React.lazy(() => import('./pages/home/industry/bookKeepers.jsx'))
const Legal = React.lazy(() => import('./pages/home/industry/legal.jsx'))
const Freelancer = React.lazy(() => import('./pages/home/industry/freelancer.jsx'))
const MarketingAgency = React.lazy(() => import('./pages/home/industry/marketingAgency.jsx'))
const ContentCreatorInfluencers = React.lazy(() => import('./pages/home/industry/contentCreator.jsx'))

const SignIn = React.lazy(() => import('./pages/auth/SignIn'))
const SignUp = React.lazy(() => import('./pages/auth/SignUp'))
const Onboarding = React.lazy(() => import('./pages/onboarding/Onboarding'))
const ForgetPassword = React.lazy(() => import('./pages/auth/ForgetPassword'))
const OtpVerification = React.lazy(() => import('./pages/auth/OtpVerification'))
const EmailVerification = React.lazy(() => import('./pages/auth/EmailVerification'))
const OnboardingProcess = React.lazy(() => import('./pages/onboarding/OnboardingProcess'))
const CommonLayOut = React.lazy(() => import('./components/common/commonlayout/CommonLayOut'))
// const Terms = React.lazy(() => import('./components/Terms'))
// const Policy = React.lazy(() => import('./components/Policy'))
// const ContactUs = React.lazy(() => import('./components/ContactUs'))

function App() {


  return (
    <div className='container-fluid'>
      <BrowserRouter>
        <Suspense fallback={'LOADING...'}>
          <Routes>
            <Route path={ROUTES.HOME} element={<PublicRoutes Component={Home}/>}/>
            <Route path={ROUTES.INTERIORDESIGNER} element={<PublicRoutes Component={InteriorDesigner}/>}/>
            <Route path={ROUTES.PHOTOGRAPHER} element={<PublicRoutes Component={Photographer}/>}/>
            <Route path={ROUTES.EVENTPLANNER} element={<PublicRoutes Component={EventPlanner}/>}/>
            <Route path={ROUTES.ARCHITECTURE} element={<PublicRoutes Component={Architecture}/>}/>
            <Route path={ROUTES.BOOKKEEPERS} element={<PublicRoutes Component={BookKeepers}/>}/>
            <Route path={ROUTES.LEGAL} element={<PublicRoutes Component={Legal}/>}/>
            <Route path={ROUTES.FREELANCER} element={<PublicRoutes Component={Freelancer}/>}/>
            <Route path={ROUTES.MARKETINGAGENCY} element={<PublicRoutes Component={MarketingAgency}/>}/>
            <Route path={ROUTES.CONTENTCREATOR} element={<PublicRoutes Component={ContentCreatorInfluencers}/>}/>

            <Route path={ROUTES.TERMS} element={<Terms/>}/>
            <Route path={ROUTES.PAYEMNT_SUCCESS} element={<PaymentSuccess />} />
            <Route path={ROUTES.POLICY} element={<Policy/>}/>
            <Route path={ROUTES.CLIENT_SIGNATURE_TEMPLATE} element={<ClientSignatureTemplate/>}/>
            <Route path={ROUTES.CLIENT_SIGNATURE_VIEW} element={<ClientViewSignatureTemplate/>}/>
            <Route path={ROUTES.CONTACT_US} element={<ContactUs/>}/>
            <Route path={ROUTES.SIGNIN} element={<PublicRoutes Component={SignIn}/>}/>
            <Route path={ROUTES.SIGNUP} element={<PublicRoutes Component={SignUp}/>}/>
            <Route path={ROUTES.ONBOARDING} element={<PublicRoutes Component={Onboarding}/>}/>
            <Route path={ROUTES.FORGET_PASS} element={<PublicRoutes Component={ForgetPassword}/>} />
            <Route path={ROUTES.SIGNUP_EMAIL} element={<PublicRoutes Component={EmailVerification}/>}/>
            <Route path={ROUTES.OTP_VERIFICATION} element={<PublicRoutes Component={OtpVerification}/>}/>
            <Route path={ROUTES.ONBOARDING_PROCESS} element={<PublicRoutes Component={OnboardingProcess}/>} />
            
            <Route path="html_page" element={<HtmlPage/>} />

            <Route element={<ProtectedRoutes Component={CommonLayOut}/>}>
              {_nav.map(route => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.component/>}
                />
              ))}
            </Route>
            <Route path={ROUTES.PAGE_NOT_FOUND} element={<NotFound />} />
            <Route path="*" element={<RedirectPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
