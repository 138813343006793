import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./Tabs.css";
import Notes from "./Notes";
import Tasks from "./Tasks";
import StatusReports from "./StatusReports";
import notesImg from "../../../assets/notes/Group 1707478809.svg";
import StatusImg from "../../../assets/notes/fi_664477.svg";
import TasksImg from "../../../assets/notes/fi_9741124.svg";
import { getProjectByBusinessService } from "../../../services";
import CommonButton from "../../../components/common/CommonButton";

const Tabs = ({ isGlobal }) => {
  const projectDetail = JSON.parse(localStorage.getItem("projectDetail"));
  const userDetail = JSON.parse(localStorage.getItem("authUserData")) || null;
  const [activeTab, setActiveTab] = useState(isGlobal ? "Tasks" : "Notes");
  const [isAdding, setIsAdding] = useState(false);
  const [projectsList, setProjectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});

  const renderComponent = () => {
    switch (activeTab) {
      case "Notes":
        return (
          <Notes
            isAdding={isAdding}
            setIsAdding={setIsAdding}
            isGlobal={isGlobal}
            selectedProject={selectedProject}
          />
        );
      case "Tasks":
        return (
          <Tasks
            isAdding={isAdding}
            setIsAdding={setIsAdding}
            isGlobal={isGlobal}
            selectedProject={selectedProject}
          />
        );
      case "Status Reports":
        return (
          <StatusReports
            isAdding={isAdding}
            setIsAdding={setIsAdding}
            isGlobal={isGlobal}
            selectedProject={selectedProject}
          />
        );
      default:
        return (
          <Notes
            isAdding={isAdding}
            setIsAdding={setIsAdding}
            isGlobal={isGlobal}
            selectedProject={selectedProject}
          />
        );
    }
  };

  const getButtonText = () => {
    if (activeTab === "Notes") {
      return "+ Notes";
    } else if (activeTab === "Tasks") {
      return "+ Tasks";
    } else {
      return "+ Status Reports";
    }
  };

  const handleAddClick = () => {
    setIsAdding((prev) => !prev);
  };

  // const getAllProjects = async () => {
  //   try {
  //     const res = await getProjectByBusinessService(
  //       userDetail?.businessDetail?.businessId
  //     );
  //     let _projects = res.data.responseData.projectData || [];
  //     if (_projects.length > 0) {
  //       _projects = _projects.map((item) => ({
  //         ...item,
  //         label: item.projectName,
  //         value: item.id,
  //       }));
  //       setProjectList(_projects);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching projects:", error);
  //   }
  // };

  // useEffect(() => {
  //   if (userDetail?.businessDetail?.businessId && isGlobal) {
  //     getAllProjects();
  //   }
  // }, [isGlobal]);

  return (
    <div className="tabs-container invoice_main_div">
      <div className="tabs-header _flex_row_spacebtw_">
        {/* {isGlobal ? (
          <Select
            classNamePrefix="cm_ui_select"
            value={selectedProject.value ? selectedProject : ""}
            onChange={(e) => setSelectedProject(e)}
            options={[{ label: "All Projects", value: "" }, ...projectsList]}
            placeholder="All Projects"
          />
        ) : (
          ""
        )} */}
        <div className="dflex tabs-header-left">
          <div
            className={`tab-item ${activeTab === "Notes" ? "active" : ""}`}
            onClick={() => {
              setActiveTab("Notes");
              setIsAdding(false);
            }} // Reset adding state on tab change
          >
            <img src={notesImg} alt="Notes" />
            <span>Notes</span>
          </div>
          <div
            className={`tab-item ${activeTab === "Tasks" ? "active" : ""}`}
            onClick={() => {
              setActiveTab("Tasks");
              setIsAdding(false);
            }}
          >
            <img src={TasksImg} alt="Tasks" />
            <span>Tasks</span>
          </div>
          <div
            className={`tab-item ${
              activeTab === "Status Reports" ? "active" : ""
            }`}
            onClick={() => {
              setActiveTab("Status Reports");
              setIsAdding(false);
            }}
          >
            <img src={StatusImg} alt="Status Reports" />
            <span>Status Reports</span>
          </div>
        </div>
        <div className="">
          {activeTab !== "Tasks" && (
            <CommonButton
              label={getButtonText()}
              fontSize="14px"
              fontWeight="700"
              icon=""
              className={`dash_common_btn`}
              onClick={handleAddClick}
              // width="100px"
            />
          )}
        </div>
      </div>

      <div className="tab-content">{renderComponent()}</div>
    </div>
  );
};

export default Tabs;
