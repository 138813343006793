import React, { useEffect, useState } from 'react';
import './invoice.css';
import CommonButton from '../../../components/common/CommonButton';
import ReactSelect from 'react-select';
import DatePicker from 'react-datepicker';
import { createOfflineInvoiceRecord, getInvoiceList } from '../../../services';
import useToast from '../../../utils/customHooks/useToast';
import { useLocation } from 'react-router-dom';
import databaseIcon from './../../../assets/dashboard/database.svg'

const OfflineRecord = ({ onCancel }) => {
  const location = useLocation();
  const { id } = location.state || {};
  const showToast = useToast();

  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [amountReceived, setAmountReceived] = useState('');
  const [paymentMode, setPaymentMode] = useState('');
  const [amountReceivedOn, setAmountReceivedOn] = useState(null);
  const [paymentReference, setPaymentReference] = useState('');
  const [notes, setNotes] = useState('');
  const [invoiceList, setInvoiceList] = useState([]);
  const [errors, setErrors] = useState({});

  const now = new Date();

  useEffect(() => {
    const newInvoiceNumber = [
      String(now.getMonth() + 1).padStart(2, "0"),
      String(now.getDate()).padStart(2, "0"),
      String(now.getHours()).padStart(2, "0"),
      String(now.getMinutes()).padStart(2, "0"),
      String(now.getSeconds()).padStart(2, "0"),
    ].join("");

    setPaymentReference(newInvoiceNumber);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const projectId = id || '';
      const token = localStorage.getItem("token");

      if (projectId) {
        try {
          const invoiceListResponse = await getInvoiceList(projectId, token);
          if (invoiceListResponse.data.status === 1) {
            setInvoiceList(invoiceListResponse?.data?.responseData?.data);
          }
        } catch (error) {
          console.error("Error fetching data", error);
        }
      }
    };

    fetchData();
  }, [id]);

  const handleInvoiceSelect = (selectedOption) => {
    setInvoiceNumber(selectedOption.value);
    setErrors((prev) => ({ ...prev, invoiceNumber: '' }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    let validationErrors = {};
    if (!invoiceNumber) validationErrors.invoiceNumber = "Invoice number is required.";
    if (!amountReceived) validationErrors.amountReceived = "Amount received is required.";
    if (!paymentMode) validationErrors.paymentMode = "Payment mode is required.";
    if (!amountReceivedOn) validationErrors.amountReceivedOn = "Date received is required.";

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) return;

    const token = localStorage.getItem("token");

    const params = {
      invoiceHash: invoiceNumber,
      receiveAmount: amountReceived,
      reviewOnDate: amountReceivedOn,
      paymentMode: paymentMode,
      reference: paymentReference,
      createBy: 'Umesh Kumar',
      notes: notes,
      invoiceId: invoiceNumber,
    };

    const response = await createOfflineInvoiceRecord(params, token);

    if (response?.data?.status === 1) {
      onCancel();
      showToast("Offline invoice created.", "success");
    } else {
      showToast("Error while creating offline invoice.", "error");
    }
  };

  const invoiceOptions = invoiceList.map((invoice) => ({
    value: invoice.id,
    label: `${invoice.invoiceHash} - ${invoice.title}`,
  }));

  return (
    <div className="offline_container">
      <h2 className='offline_record'>Record Manual/Offline Payments</h2>
      <form className="offline_form" onSubmit={handleSubmit}>
        
        {/* Invoice Number Section */}
        <div className="offline_form-group">
          <label className='offline_label'>Invoice Number</label>
          <ReactSelect
            className='offline_input'
            options={invoiceOptions}
            onChange={handleInvoiceSelect}
            placeholder="Select Invoice"
          />
          {errors.invoiceNumber && <p className="error">{errors.invoiceNumber}</p>}
        </div>

        {/* Amount Received and Amount Received On Section */}
        <div className="offline_form-row">
          <div className="offline_form-group">
            <label className='offline_label'>Amount Received</label>
            <input
              className='offline_input offline_input1'
              type="text"
              value={amountReceived}
              onChange={(e) => {
                setAmountReceived(e.target.value);
                if (e.target.value) setErrors((prev) => ({ ...prev, amountReceived: '' }));
              }}
              placeholder="₹0.0"
            />
            {errors.amountReceived && <p className="error">{errors.amountReceived}</p>}
          </div>
          
          <div className="offline_form-group">
            <label className='offline_label'>Amount Received On</label>
            <DatePicker
              selected={amountReceivedOn}
              onChange={(date) => {
                setAmountReceivedOn(date);
                if (date) setErrors((prev) => ({ ...prev, amountReceivedOn: '' }));
              }}
              placeholderText="Select Date"
              dateFormat="MMMM dd, yyyy"
              minDate={now}
              className='offline_input offline_input1'
            />
            {errors.amountReceivedOn && <p className="error">{errors.amountReceivedOn}</p>}
          </div>
        </div>

        {/* Payment Mode and Payment Reference Section */}
        <div className="offline_form-row">
          <div className="offline_form-group">
            <label className='offline_label'>Payment Mode</label>
            <ReactSelect
              className='offline_input'
              options={[
                { value: 'cash', label: 'Cash' },
                { value: 'cheque', label: 'Cheque' },
                { value: 'upi', label: 'UPI' },
                { value: 'credit_card', label: 'Credit Card' },
                { value: 'bank_transfer', label: 'Bank Transfer' }
              ]}
              onChange={(e) => {
                setPaymentMode(e.value);
                if (e.value) setErrors((prev) => ({ ...prev, paymentMode: '' }));
              }}
              placeholder="Select Payment Mode"
            />
            {errors.paymentMode && <p className="error">{errors.paymentMode}</p>}
          </div>
          
          <div className="offline_form-group">
            <label className='offline_label'>Payment Reference Number</label>
            <input
              className='offline_input offline_input1'
              type="text"
              value={paymentReference}
              readOnly
              placeholder="Reference number"
            />
          </div>
        </div>

        {/* Notes Section */}
        <div className="offline_form-group full-width">
          <label className='offline_label'>Notes</label>
          <textarea
            className='offline_input full-width-textarea'
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Add any notes here"
            rows={5}
            minLength={1}
            maxLength={500}
          />
        </div>

        {/* Additional Information Section */}
        <div className="offline_payment-setup">
        <img src={databaseIcon} alt="databaseIcon" width={21} height={21}/>
          <div>
            <p>
            <strong>Set Up Online Payments!</strong></p>
            <p>Send payment links to your customers and collect payments online. No manual entries needed.</p> 
            <p>To get started, go to Settings → Company settings → Payments</p>
          </div>
        </div>

        <div className="contract_main_button">
          <CommonButton
            label="Cancel"
            fontSize="14px"
            fontWeight="700"
            className="dash_common_btn white_bg_btn"
            icon={false}
            onClick={onCancel}
          />
          <CommonButton
            label="Save"
            fontSize="14px"
            fontWeight="700"
            className="dash_common_btn"
            icon=""
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default OfflineRecord;
