import { RemoveIcon } from "../../../components/SVG";


const TableView = ({list, deleteCallback}) => {

    return(
        <div className="cm_table_view mt-4 mb-5">
            <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th></th>
                        <th className="ps-5">Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Role</th>
                        <th className="text-center">Action</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        list.map((item, index) => (
                            <tr key={item.id}>
                                {console.log(item,'itemitemitem')}
                                <td></td>
                                <td className="ps-5">{(!item.firstName && !item.lastName) ? '-' : item.firstName} {item.lastName}</td>
                                <td>{item.email || ''}</td>
                                <td>{item.phone_number || '-'}</td>
                                <td>{item.role || 'Client'}</td>
                                <td className="text-center">
                                    <span onClick={() => deleteCallback(item.id)} className="cursor-pointer"><RemoveIcon /></span>
                                </td>
                            </tr>
                        ))
                    }
                    
                    
                </tbody>
                </table>
            </div>
        </div>
    )
}

export default TableView;