import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import GlobalSearch from "../../components/common/GlobalSearch";
import { logout } from "../../features/auth/authSlice";
import { persistor } from "../../store";

import plus from "../../assets/dashboard/plus.svg";
import searchIcon from "../../assets/dashboard/Search.svg";
import penIcon from "../../assets/logos/pen.svg";
import { useState, useEffect } from "react";
import { getVendorsByBusinessService, deleteVendorService } from "../../services";
import VendorModalFrom from "./VendorModalFrom";
import { RemoveIcon } from "../../components/SVG";

const Vendors = () => {
    let userDetail = JSON.parse(localStorage.getItem("authUserData")) || null;
    const token = localStorage.getItem('token');
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [vandorList, setVandorList] = useState([]);
    const [activeVendor, setActiveVendor] = useState({});
    const [showVendorModal, setShowVendorModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); // New state for search term

    const handleLogout = () => {
        localStorage.clear();
        dispatch(logout());
        persistor.purge()
        navigate("/signin");
    };

    const getVendorsByBusiness = async () => {
        let query = `?businessId=${userDetail?.businessDetail?.businessId}`;
        try {
            const res = await getVendorsByBusinessService(query, token);
            if(res?.data?.status){
                setVandorList(res.data.responseData.vendors || [])
            }
        } catch (error) {
            console.error('Error fetching vendors:', error);
        }
    };


    const handleCallBack = () => {
        getVendorsByBusiness();
    }

    useEffect(() => {
        if(userDetail?.businessDetail?.businessId){
            getVendorsByBusiness();
        }
    }, []);

    const handleCloseModal = () => {
        setShowVendorModal(false);
        setActiveVendor({})
    };

    // Filter the vendor list based on search term
    const filteredVendors = vandorList.filter(item =>
        item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.vendorCompanyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.vendorCategory.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const handleDelete = (item) => {
        deleteVendorService(item.id).then(res => {
            getVendorsByBusiness();
        })
    }


    const handleEdit = item => {
        setShowVendorModal(true)
        setActiveVendor(item)
    }

    return (
        <>
        {showVendorModal ? <VendorModalFrom activeVendor={activeVendor} callback={handleCallBack} onHide={handleCloseModal} /> : ''}
        <div className="dashboard_upper">
            <GlobalSearch />
            <div className="logout_main_div">
                <div className="name_intials_dash_upper">
                    {userDetail.firstName ? userDetail.firstName.charAt(0).toUpperCase() : ''}{userDetail.lastName ? userDetail.lastName.charAt(0).toUpperCase() : ''}
                </div>
                <div className="logout_div">
                    <p onClick={handleLogout}>Logout</p>
                </div>
            </div>
        </div>

        <div className="template_page_wrapper">
            <div className="template_box_wrapper">
                <h3>Vendors</h3>
                <p>Save your vendor information in the vendors section so your team can easily access and manage it for future projects, ensuring seamless collaboration.</p>

                <div className="d-flex justify-content-between align-items-center mt-5">
                    <div className="cm_search">
                        <img src={searchIcon} alt="searchicon" />
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Search"
                            value={searchTerm} // Bind input to searchTerm
                            onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm on input change
                        />
                    </div>
                    <span onClick={() => setShowVendorModal(true)} className="common-button shadow dash_common_btn px-5">
                        <img src={plus} alt='icon'/> <b> New</b> 
                    </span>
                </div>

                <div className="cm_table_view mt-4 mb-5">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Company</th>
                                    <th>Address</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Website</th>
                                    <th>Category</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredVendors?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.firstName}</td>
                                        <td>{item.lastName}</td>
                                        <td>{item.vendorCompanyName}</td>
                                        <td>{item.address}</td>
                                        <td>{item.country_code} {item.phone_number}</td>
                                        <td>{item.email}</td>
                                        <td>{item.vendorWebsite}</td>
                                        <td>{item.vendorCategory}</td>
                                        <td>
                                            <span style={{cursor: 'pointer'}} onClick={() => handleDelete(item)}><RemoveIcon /></span>
                                            <span style={{cursor: 'pointer'}} onClick={() => handleEdit(item)} className="ms-3"><img src={penIcon} /></span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Vendors;
