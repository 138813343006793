
import { useEffect, useState } from "react";
import logo from "../../../assets/logos/fb.svg";
import CommonButton from "../../../components/common/CommonButton";
import { postHtmlTemplate, getProjectByIdService } from "../../../services";
import TemplatePreview from "../dealroom/TemplatePreview";
import { formatDate2 } from "../../../utils/helper";


function ChangeRequestPreview({ formData, handleCancel, refreshChangeRqstList, projectDetail }) {
  const [loader, setLoader] = useState(false);
  const [pdfSrc, setPdfSrc] = useState("");
  const [data, setData] = useState({});


  const authUserData = JSON.parse(localStorage.getItem("authUserData")) || {};



  useEffect(() => {
    localStorage.removeItem("isCR");
  }, [])





  const generatePDF = async () => {
    setLoader(true);
    const capture = document.querySelector("#change_request");
    if (!capture) {
      console.error("Element not found");
      setLoader(false);
      return;
    }

    const htmlString = capture.outerHTML;
    const token = localStorage.getItem("token");
    try {

     let obj = {
      text: htmlString,
      type: 1
     }



      const response = await postHtmlTemplate(obj, token);
      if (response.data.status === 1) {
        setPdfSrc(response.data?.responseData?.url)
        localStorage.setItem("pdfUrl", response.data?.responseData?.url);
        localStorage.setItem("isCR", true);
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
    setLoader(false);
  };




  useEffect(() => {
    if (formData && Object.keys(formData).length > 0) {
      setData(formData);
    }
  }, [formData]);




  return (
    <>
      {loader ? (
        "Loading Preview....."
      ) : pdfSrc ? (
        <TemplatePreview
          previewUrl={pdfSrc}
          cancelHandler={() => (setPdfSrc(""), handleCancel())}
          type={2}
          _refreshSignersList={refreshChangeRqstList}
          crData={formData}
        />
      ) : data && Object.keys(data)?.length > 0 ? (
        <div
          className="contract_left"
          style={{
            display: "block",
            height: "70vh",
            overflowY: "scroll",
            width: "100%",
          }}
        >
          <div
            id="change_request"
            className="contract_left_template"
            style={{
              padding: 0,
              border: "none",
              maxWidth: '80%',
              width: '100%',
              margin: '0 auto'
            }}
          >
            <div
              style={{
                position: 'relative',
                border: '1px solid #0000001a',
                padding: '15px 20px 20px',
                margin: '40px 0 20px',
                borderRadius: '8px' }}>
              
              <img
                src={formData?.logo || logo}
                alt="business logo" 
                style={{
                display: 'block',
                margin: '0 auto',
                width: '60px' }}/>
              
              <ul style={{
                    listStyle: 'none',
                    margin: '0',
                    padding: '0'
              }}>
                <li style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                  }}>
                  <p><b>{data?.clientField?.signName || ''}</b></p>
                  <p><b>{formData?.companyField?.companyName || ''}</b></p>
                </li>
                <li style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                  }}>  
                  <p><b>{projectDetail?.clients[0]?.address || ''}</b></p>
                  <p><b>{authUserData?.businessDetail?.businessAddress || ''}</b></p>
                </li>
                <li style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                  }}>

                  <p><b>{projectDetail?.clients[0]?.phone_number ? `${projectDetail?.clients[0]?.country_code || ''} ${projectDetail?.clients[0]?.phone_number || ''}` : ''}</b></p>
                  <p><b>{authUserData?.businessDetail?.phone_number ? `${authUserData?.businessDetail?.country_code || ''} ${authUserData?.businessDetail?.phone_number || ''}` : ''}</b></p>
                </li>
                <li style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                  }}>
                  <p><b>{projectDetail?.clients[0]?.email || ''}</b></p>
                  <p><b>{authUserData?.businessDetail?.email || ''}</b></p>
                </li>
              </ul>
            </div>


            <div style={{
              border: '1px solid #0000001a',
              padding: '38px 20px',
              borderRadius: '8px'
            }}>
              <h2 style={{
                fontSize: '22px',
                lineHeight: '30px',
                fontWeight: '700',
                margin: '0'
              }}>{formData?.documentTitle}</h2>
              <ul style={{
                    listStyle: 'none',
                    margin: '0',
                    padding: '0'
                }}>
                  <li style={{display: 'flex',justifyContent: 'space-between',alignItems: 'center',marginTop: '30px'}}>
                    <div style={{width: '50%', marginRight: '15px' }}>
                      <label>Project</label>
                      <input
                        type="text"
                        placeholder="Project"
                        value={data?.overview?.projectName}
                        style={{ "fontSize": "14px","outline": "none !important","padding": "15px","border": "1px solid #e7e8ea","borderRadius": "8px","color": "black","display": "block","width": "100%", minHeight: '53px'}} />
                    </div>

                    {/* <div style={{width: '50%' }}>
                      <label>Status</label>
                      <input
                        type="text"
                        placeholder="Status"
                        value={data?.overview?.projectStatus?.name}
                        style={{"fontSize": "14px","outline": "none !important","padding": "15px","border": "1px solid #e7e8ea","borderRadius": "8px",color: data?.overview?.projectStatus?.color,"display": "block","width": "100%", minHeight: '53px' }}/>
                    </div> */}
                </li>




                <li style={{display: 'flex',justifyContent: 'space-between',alignItems: 'center',marginTop: '30px'}}>
                <div style={{width: '50%', marginRight: '15px' }}>
                      <label><small>Change Requestor ( Person/Company that requested the change )</small></label>
                      <input
                        type="text"
                        placeholder="Change Requestor"
                        value={data?.overview?.requestor}
                        style={{ "fontSize": "14px","outline": "none !important","padding": "15px","border": "1px solid #e7e8ea","borderRadius": "8px","color": "black","display": "block","width": "100%", minHeight: '53px'}} />
                    </div>

                    <div style={{width: '50%', position: 'relative', top: '4px' }}>
                      <label className="mt-2"><small>Effective Date</small></label>
                      {console.log(data?.clientField?.effectiveDate, 'datadata')}
                     
                      <p
                        style={{
                          "fontSize": "14px",
                          "outline": "none !important",
                          "padding": "15px",
                          "border": "1px solid #e7e8ea",
                          "borderRadius": "8px",
                          "display": "block",
                          "width": "100%",
                          minHeight: '53px' }}>{data?.clientField?.effectiveDate ? formatDate2(data?.clientField?.effectiveDate) : ''}</p>
                    </div>
                </li>

              </ul>

              <h2 style={{
                fontSize: '22px',
                lineHeight: '30px',
                fontWeight: '700',
                margin: '0',
                marginTop: '30px'
              }}>Change Category</h2>
             
              <div style={{ marginTop: "22px", "border":"1px solid #0e1e2e1a","padding":"25px 20px","borderRadius":"8px","position":"relative" }}>
                
                <ul style={{"listStyle":"none","margin":"0","padding":"0"}}>
                  <li style={{"display":"inline-block","width":"45%","verticalAlign":"text-top"}}>
                    <span>
                      <input type="checkbox" style={{ marginRight: "17px" }} checked={data?.category[0]?.value} />
                      Scope
                    </span>
                  </li>

                  <li style={{"display":"inline-block","width":"45%","verticalAlign":"text-top"}}>
                    <span>
                      <input type="checkbox" style={{ marginRight: "17px" }} checked={data?.category[1]?.value} />
                      Cost
                    </span>
                  </li>
                  <li style={{"display":"inline-block","width":"45%","verticalAlign":"text-top"}}>
                    <span>
                      <input type="checkbox" style={{ marginRight: "17px" }} checked={data?.category[2]?.value} />
                      Requirements/Deliverables
                    </span>
                  </li>
                  <li style={{"display":"inline-block","width":"45%","verticalAlign":"text-top"}}>
                    <span>
                      <input type="checkbox" style={{ marginRight: "17px" }} checked={data?.category[3]?.value} />
                      Testing/Quality
                    </span>
                  </li>
                  <li style={{"display":"inline-block","width":"45%","verticalAlign":"text-top"}}>
                    <span>
                      <input type="checkbox" style={{ marginRight: "17px" }} checked={data?.category[4]?.value} />
                      Corrective Action
                    </span>
                  </li>
                  <li style={{"display":"inline-block","width":"45%","verticalAlign":"text-top"}}>
                    <span>
                      <input type="checkbox" style={{ marginRight: "17px" }} checked={data?.category[5]?.value} />
                      Defect Repair
                    </span>
                  </li>
                  <li style={{"display":"inline-block","width":"45%","verticalAlign":"text-top"}}>
                    <span>
                      <input type="checkbox" style={{ marginRight: "17px" }} checked={data?.category[6]?.value} />
                      Schedule
                    </span>
                  </li>
                  <li style={{"display":"inline-block","width":"45%","verticalAlign":"text-top"}}>
                    <span>
                      <input type="checkbox" style={{ marginRight: "17px" }} checked={data?.category[7]?.value} />
                      Resources
                    </span>
                  </li>
                  <li style={{"display":"inline-block","width":"45%","verticalAlign":"text-top"}}>
                    <span>
                      <input type="checkbox" style={{ marginRight: "17px" }} checked={data?.category[8]?.value} />
                      Other
                    </span>

                    <input
                        type="text"
                        style={{ "fontSize": "14px","outline": "none !important","padding": "15px","border": "1px solid #e7e8ea","borderRadius": "8px","color": "black","display": "block","width": "100%", minHeight: '53px'}}
                        placeholder="If other, describe"
                        value={data?.otherText}
                        disabled={!data?.category[8]?.value} />
                  </li>
                </ul>
                
                
              </div>

              <div style={{marginTop: '30px'}}>
                  <label> Describe the change being requested ?</label>
                    <textarea
                      rows="4"
                      cols="50"
                      style={{"fontSize":"14px","outline":"none !important","width":"100%","padding":"15px","border":"1px solid #e7e8ea","borderRadius":"8px","color":"black", height: "150px"}}
                      placeholder="Write the details of the change request"
                      value={data?.description}
                      maxLength={200}
                    />
              </div>

              <div style={{marginTop: '30px'}}>
                  <label> Describe the reason for the change ?</label>
                    <textarea
                      rows="4"
                      cols="50"
                      style={{"fontSize":"14px","outline":"none !important","width":"100%","padding":"15px","border":"1px solid #e7e8ea","borderRadius":"8px","color":"black", height: "150px"}}
                      placeholder="Write the reason for the change"
                      value={data?.reason}
                      maxLength={200}
                    />
              </div>


              <div style={{marginTop: '30px'}}>
                  <label> What will be the impact of change ?</label>
                    <textarea
                      rows="4"
                      cols="50"
                      style={{"fontSize":"14px","outline":"none !important","width":"100%","padding":"15px","border":"1px solid #e7e8ea","borderRadius":"8px","color":"black", height: "150px"}}
                      placeholder={`Scope impact :\n\nCost impact :\n\nSchedule impact :`}
                      value={data?.impact}
                      maxLength={200}
                    />
              </div>


              <div style={{marginTop: '30px'}}>
                  <label> IN WITNESS WHEREOFF, the parties here to have executed this
                  change request as of the effective date.</label>
                  <input
                    type="text"
                    style={{ "fontSize": "14px","outline": "none !important","padding": "15px","border": "1px solid #e7e8ea","borderRadius": "8px","color": "black","display": "block","width": "100%", minHeight: '53px'}}
                    placeholder="Signature" disabled />
              </div>

              <div style={{marginTop: '30px'}}>
                  <label>Name ( Person from the company that will sign the change
                    request )</label>
                  <input
                    placeholder="Company owner name"
                    value={data?.clientField?.name}
                    type="text"
                    style={{ "fontSize": "14px","outline": "none !important","padding": "15px","border": "1px solid #e7e8ea","borderRadius": "8px","color": "black","display": "block","width": "100%", minHeight: '53px'}}
                    />
              </div>


              <div style={{marginTop: '30px'}}>
                  <label>Company Name</label>
                  <input
                    placeholder="Pre-populated with company name which can be edited"
                    value={data?.companyField?.companyName}
                    type="text"
                    style={{ "fontSize": "14px","outline": "none !important","padding": "15px","border": "1px solid #e7e8ea","borderRadius": "8px","color": "black","display": "block","width": "100%", minHeight: '53px'}}
                    />
              </div>

              <div style={{marginTop: '30px'}}>
                <label>Signature</label>
                  <input
                    placeholder="Signature"
                    type="text"
                    style={{ "fontSize": "14px","outline": "none !important","padding": "15px","border": "1px solid #e7e8ea","borderRadius": "8px","color": "black","display": "block","width": "100%", minHeight: '53px'}}
                    disabled />
              </div>


              <div style={{marginTop: '30px'}}>
                  <label> Client Name ( Requester Name )</label>
                  <input
                    placeholder="Requester name"
                    value={data?.clientField?.signName}
                    type="text"
                    style={{ "fontSize": "14px","outline": "none !important","padding": "15px","border": "1px solid #e7e8ea","borderRadius": "8px","color": "black","display": "block","width": "100%", minHeight: '53px'}}
                    />
              </div>


            </div>
          </div>
          <div className="contract_main_button">
            <CommonButton
              label="Cancel"
              fontSize="14px"
              fontWeight="700"
              className="dash_common_btn white_bg_btn"
              icon={false}
              onClick={handleCancel}
            />
            <CommonButton
              // label="Add Magic Fields"
              label="Next"
              fontSize="14px"
              fontWeight="700"
              className="dash_common_btn"
              icon={false}
              onClick={generatePDF}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default ChangeRequestPreview;
