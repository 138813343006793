import { Modal } from "react-bootstrap";
import { getClientbyBusinessIdService } from "../../../services";
import { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import PhoneNumber from "../../../components/PhoneNumber";

const AddClientModal = ({ onHide, cmCallback }) => {
  const businessDetails = JSON.parse(localStorage.getItem("authUserData")) || null;

  const [allClients, setAllClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  const [clientDetails, setClientDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone_number: "",
    address: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone_number: "",
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateFields = () => {
    const newErrors = {};
    if (!clientDetails.firstName) newErrors.firstName = "First name is required.";
    if (!clientDetails.lastName) newErrors.lastName = "Last name is required.";
    if (!clientDetails.email) {
      newErrors.email = "Email is required.";
    } else if (!validateEmail(clientDetails.email)) {
      newErrors.email = "Invalid email format.";
    }
    if (!clientDetails.phone_number) newErrors.phone_number = "Phone number is required.";
    return newErrors;
  };

  const getAllClients = useCallback(async () => {
    const businessId = businessDetails?.businessDetail?.businessId;
    if (businessId) {
      try {
        const res = await getClientbyBusinessIdService(businessId);
        if (res.data.status === 1) {
          const clients = res.data.responseData.clients || [];
          const options = clients.map((client) => ({
            value: client.id,
            label: client.clientName,
            ...client,
          }));
          setAllClients(options);
        }
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    }
  }, [businessDetails]);

  useEffect(() => {
    getAllClients();
  }, []);

  const handleClientChange = (selectedOption) => {
    if (selectedOption) {
      setClientDetails({
        firstName: selectedOption.firstName,
        lastName: selectedOption.lastName,
        email: selectedOption.email,
        phone_number: `${selectedOption.country_code}${selectedOption.phone_number}`,
        address: selectedOption.address || "",
      });

      setErrors({
        firstName: "",
        lastName: "",
        email: "",
        phone_number: "",
      });
    } else {
      setClientDetails({
        firstName: "",
        lastName: "",
        email: "",
        phone_number: "",
        address: "",
      });
    }
    setSelectedClient(selectedOption);
  };

  const handleInputChange = (field, value) => {
    setClientDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "", // Clear error when user starts typing
    }));
  };

  const handleSubmit = () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    cmCallback('members')
    console.log("Client data submitted:", clientDetails);
  };

  return (
    <Modal show={true} onHide={onHide} backdrop="static">
      <Modal.Body className="p-0">
        <div className="_form_lable">
          <h3 className="modal_heading mb-4">Add Client</h3>

          <div className="form-group">
            <label>Select client</label>
            <Select
              value={selectedClient}
              onChange={handleClientChange}
              options={allClients}
              isClearable
              placeholder="Select client"
            />
          </div>

          <div className="line_or my-4"><span>Or</span></div>

          <div className="row mb-4">
            <div className="col-md-6">
              <div className="form-group">
                <label>First Name<span className="_imp_">*</span></label>
                <input
                  value={clientDetails.firstName}
                  type="text"
                  onChange={(e) => handleInputChange("firstName", e.target.value)}
                  className="form-control"
                />
                {errors.firstName && <small className="text-danger">{errors.firstName}</small>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Last Name<span className="_imp_">*</span></label>
                <input
                  value={clientDetails.lastName}
                  type="text"
                  onChange={(e) => handleInputChange("lastName", e.target.value)}
                  className="form-control"
                />
                {errors.lastName && <small className="text-danger">{errors.lastName}</small>}
              </div>
            </div>
          </div>

          <div className="form-group mb-4">
            <label>Email<span className="_imp_">*</span></label>
            <input
              value={clientDetails.email}
              type="email"
              onChange={(e) => handleInputChange("email", e.target.value)}
              className="form-control"
            />
            {errors.email && <small className="text-danger">{errors.email}</small>}
          </div>

          <div className="form-group mb-4">
            <label>Mobile Number<span className="_imp_">*</span></label>
            <PhoneNumber
              value={clientDetails.phone_number}
              setValue={(value) => handleInputChange("phone_number", value)}
              isError={!!errors.phone_number}
              setError={() => {}}
              isSubmitTouch={true}
            />
            {errors.phone_number && <small className="text-danger">{errors.phone_number}</small>}
          </div>

          <div className="form-group">
            <label>Address<span>(optional)</span></label>
            <input
              value={clientDetails.address}
              type="text"
              onChange={(e) => handleInputChange("address", e.target.value)}
              className="form-control"
            />
          </div>

          <div className="d-flex justify-content-between align-baseline mt-5">
            <span onClick={onHide} className="_btn_transparent_ modal_div_button px-5">Cancel</span>
            <span onClick={handleSubmit} className="_btn_normal_ modal_div_button px-5">Submit</span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddClientModal;
