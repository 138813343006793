import { format, parseISO } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import searchIcon from "../../../assets/dashboard/Search.svg";
import filterIcon from "../../../assets/dashboard/filter_icon.svg";
import plusIcon from "../../../assets/dashboard/plus.svg";
import plusBlueIcon from "../../../assets/dashboard/plus_blue.svg";
import arrowBottomIcon from "../../../assets/logos/arrow-bottom.svg";
import deleteIcon from "../../../assets/logos/del.svg";
import CommonButton from "../../../components/common/CommonButton";
import CustomToggle from "../../../components/common/CustomToggle";
import ProjectDetailsFilter from "../../../modals/ProjectDetailsFilter";
import {
  getClientbyBusinessIdService,
  getMembersByBusinessIdService,
  postProjectManagement,
  updateProjectManagement,
} from "../../../services";
import {
  completedStatus,
  priorityStatus,
  projectStatus,
} from "../../../utils/constant";
import useToast from "../../../utils/customHooks/useToast";
import { addDays } from "../../../utils/helper";
import TableDateComponent from "./TableDateComponent";
import TableSelectComponent from "./TableSelectComponent";
import DiscardModal from "../../../modals/DiscardModal";

const dateRender = (date) => {
  try {
    return format(parseISO(date?.replace("Z", "")), "MMMM dd, yyyy");
  } catch (error) {
    return "";
  }
};

const bufferDateChange = (dateString, days) => {
  const newDate = addDays(dateString?.replace("Z", ""), days);
  try {
    return format(newDate, "yyyy-MM-dd'T'HH:mm:ss'Z'");
  } catch (error) {
    return dateString;
  }
};

const AddProjectPlan = ({
  handleCancel,
  staticDetails,
  userDetails,
  isEditing = false,
  projectRowData = null,
}) => {
  const [isDiscard, setIsDiscard] = useState(false);
  const [onOffState, setOnOffState] = useState({
    isShareWithClient: false,
    isOnlySection: false,
    isClientDueDate: false,
    clientDueDate: 0
  });
  const [bufferDays, setBufferDays] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [searchSectionName, setSearchSectionName] = useState("");
  const [filterStatus, setFilterStatus] = useState([
    ...projectStatus.map((item, index) => ({
      ...item,
      id: index + 1,
      isChecked: false,
    })),
  ]);
  const [filterPriority, setFilterPriority] = useState([
    ...priorityStatus.map((item, index) => ({
      ...item,
      id: index + 1,
      isChecked: false,
    })),
  ]);
  const [filterOwner, setFilterOwner] = useState([]);
  const [planName, setPlanName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sections, setSections] = useState([]);
  const [filteredSections, setFilteredSections] = useState([]);
  const showToast = useToast();
  const debounceTimeout = useRef(null);
  const newRowRef = useRef(null);

  const handleDiscardOpen = () => setIsDiscard(true);
  const handleDiscardClose = async (key) => {
    if (key === "discard") {
      setIsDiscard(false);
      handleCancel();
    } else {
      setIsDiscard(false);
      await handleSavePlan();
    }
  };



  const handleSearchSectionName = (e) => {
    const value = e.target.value;
    setSearchSectionName(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      applyFilters(value, filterStatus);
    }, 500);
  };

  const handlePlanNameChange = (e) => {
    const { value } = e.target;
    setPlanName(value);
  };

  const applyFilters = (
    searchQuery,
    _filterStatus,
    _filterPriority,
    _filterOwner
  ) => {
    const checkedStatusFilters = _filterStatus
      .filter((filter) => filter.isChecked)
      .map((filter) => filter.value.toLowerCase());

    const checkedPriorityFilters = _filterPriority
      .filter((filter) => filter.isChecked)
      .map((filter) => filter.value.toLowerCase());

    const checkedOwnerFilters = _filterOwner
      .filter((filter) => filter.isChecked)
      .map((filter) => filter.value.toLowerCase());

    let filteredList = sections;

    if (searchQuery) {
      filteredList = filteredList.filter((item) =>
        item.sectionName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (checkedStatusFilters.length > 0) {
      filteredList = filteredList
        .map((section) => {
          const filteredTasks = section.tasks.filter((task) =>
            checkedStatusFilters.includes(task.status.name.toLowerCase())
          );
          return { ...section, tasks: filteredTasks };
        })
        .filter((section) => section.tasks.length > 0);
    }

    if (checkedPriorityFilters.length > 0) {
      filteredList = filteredList
        .map((section) => {
          const filteredTasks = section.tasks.filter((task) =>
            checkedPriorityFilters.includes(task.priority.name.toLowerCase())
          );
          return { ...section, tasks: filteredTasks };
        })
        .filter((section) => section.tasks.length > 0);
    }

    if (checkedOwnerFilters.length > 0) {
      filteredList = filteredList
        .map((section) => {
          const filteredTasks = section.tasks.filter((task) =>
            checkedOwnerFilters.includes(task.owner.toLowerCase())
          );
          return { ...section, tasks: filteredTasks };
        })
        .filter((section) => section.tasks.length > 0);
    }

    setFilteredSections(filteredList);
  };

  const handleShowFilter = () => setShowFilter(true);

  const addRow = () => {
    const newRow = {
      id: sections.length + 1,
      sectionName: "",
      tasks: [
        {
          id: 1,
          task: { name: "", color: "#000000" },
          status: {
            icon: "",
            name: "",
            color: "",
            value: 0,
            isClick: false,
            position: 0,
            isSelected: false,
          },
          owner: "",
          startDate: "",
          internalDueDate: "",
          clientDueDate: "",
          priority: {
            icon: "",
            name: "",
            color: "",
            value: 0,
            isClick: false,
            position: 0,
            isSelected: false,
          },
          completed: {
            name: 0,
            progress: 0,
          },
        },
      ],
      expanded: true,
    };
    setSections([...sections, newRow]);
    setFilteredSections([...filteredSections, newRow]);

    // Scroll to the new row after it has been added
    setTimeout(() => {
      if (newRowRef.current) {
        newRowRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 0);
  };

  const handleInputChange = (
    e,
    rowId,
    field,
    isSubRow = false,
    subRowId = null
  ) => {
    const { value } = e.target;

    const updatedSections = sections.map((section) => {
      if (section.id === rowId) {
        if (isSubRow) {
          const updatedTasks = section.tasks.map((task) => {
            if (task.id === subRowId) {
              switch (field) {
                case "task":
                  return { ...task, task: { name: value, color: "#000000" } };
                case "status":
                  const statusValue = projectStatus.find(
                    (p) => p.value === value
                  );
                  return {
                    ...task,
                    status: {
                      icon: "",
                      name: statusValue.value,
                      color: statusValue.color,
                      value: 0,
                      isClick: false,
                      position: 0,
                      isSelected: true,
                    },
                  };
                case "owner":
                  return { ...task, owner: value };
                case "priority":
                  const priorityValue = priorityStatus.find(
                    (p) => p.value === value
                  );
                  return {
                    ...task,
                    priority: {
                      icon: "",
                      name: priorityValue.value,
                      color: priorityValue.color,
                      value: 0,
                      isClick: false,
                      position: 0,
                      isSelected: true,
                    },
                  };
                case "completed":
                  const completedValue = completedStatus.find(
                    (p) => p.value === +value
                  );
                  return {
                    ...task,
                    completed: {
                      name: completedValue.value,
                      progress: completedValue.progress,
                    },
                  };
                case "internalDueDate":
                  if (onOffState.addBuffer && bufferDays) {
                    return {
                      ...task,
                      internalDueDate: value,
                      clientDueDate: bufferDateChange(value, bufferDays, "add"),
                    };
                  }
                  return {
                    ...task,
                    internalDueDate: value,
                    clientDueDate: value,
                  };
                default:
                  return { ...task, [field]: value };
              }
            }
            return task;
          });
          return { ...section, tasks: updatedTasks };
        } else {
          return { ...section, [field]: value };
        }
      }
      return section;
    });

    setSections(updatedSections);
    setFilteredSections(updatedSections);
  };

  const toggleExpand = (id) => {
    setSections(
      sections.map((row) =>
        row.id === id ? { ...row, expanded: !row.expanded } : row
      )
    );
    setFilteredSections(
      filteredSections.map((row) =>
        row.id === id ? { ...row, expanded: !row.expanded } : row
      )
    );
  };

  const handleAddTask = (rowId) => {
    const newTaskId = addSubRow(rowId);
    setTimeout(() => {
      const newTaskInput = document.querySelector(
        `#task-input-${rowId}-${newTaskId}`
      );
      if (newTaskInput) {
        newTaskInput.focus();
        newTaskInput.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 0);
  };

  const addSubRow = (id) => {
    let newTaskId;
    const updatedSections = sections.map((row) => {
      if (row.id === id) {
        const newTask = {
          id: row.tasks.length + 1,
          task: { name: "", color: "#000000" },
          status: {
            icon: "",
            name: "",
            color: "",
            value: 0,
            isClick: false,
            position: 0,
            isSelected: false,
          },
          owner: "",
          startDate: "",
          internalDueDate: "",
          clientDueDate: "",
          priority: {
            icon: "",
            name: "",
            color: "",
            value: 0,
            isClick: false,
            position: 0,
            isSelected: false,
          },
          completed: {
            name: 0,
            progress: 0,
          },
        };
        newTaskId = newTask.id;
        return {
          ...row,
          tasks: [...row.tasks, newTask],
        };
      }
      return row;
    });

    setSections(updatedSections);

    setFilteredSections(
      updatedSections.map((row) =>
        row.id === id ? { ...row, tasks: [...row.tasks] } : row
      )
    );

    return newTaskId;
  };

  const deleteSubRow = (rowId, subRowId) => {
    setSections(
      sections.map((row) =>
        row.id === rowId
          ? {
              ...row,
              tasks: row.tasks.filter((subRow) => subRow.id !== subRowId),
            }
          : row
      )
    );
    setFilteredSections(
      filteredSections.map((row) =>
        row.id === rowId
          ? {
              ...row,
              tasks: row.tasks.filter((subRow) => subRow.id !== subRowId),
            }
          : row
      )
    );
  };

  const deleteRow = (rowId) => {
    setSections(sections.filter((row) => row.id !== rowId));
    setFilteredSections(filteredSections.filter((row) => row.id !== rowId));
  };

  const handleSavePlan = async () => {
    setIsLoading(true);
    if (!planName) {
      showToast("Plan Name is Required!", "error");
      setIsLoading(false);
      return;
    }



    const data = {
      isShareWithClient: onOffState.isShareWithClient,
      isOnlySection: onOffState.isOnlySection,
      isClientDueDate: onOffState.isClientDueDate,
      clientDueDate: onOffState.clientDueDate ? parseInt(onOffState.clientDueDate) : '',
      planName,
      sections: sections.map(({ id, expanded, ...section }) => ({
        ...section,
        tasks: section.tasks.map(({ id, ...task }) => ({
          ...task,
          startDate: task.startDate,
          internalDueDate: task.internalDueDate,
          clientDueDate: task.clientDueDate,
        })),
      })),
      createBy:
        isEditing && projectRowData !== null
          ? projectRowData?.createBy
          : `${userDetails?.firstName} ${userDetails?.lastName}`,
      projectId:
        isEditing && projectRowData !== null
          ? projectRowData?.projectId
          : staticDetails?.id,
    };

    try {
      if (isEditing && projectRowData !== null) {
        await updateProjectManagement(projectRowData?.id, data);
        showToast("Successfully Updated Project Plan!", "success");
      } else {
        await postProjectManagement(data);
        showToast("Successfully Added Project Plan!", "success");
      }
      setIsLoading(false);
      handleCancel();
    } catch (error) {
      console.log("Couldn't save Project Plan", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (staticDetails && staticDetails.businessId) {
      (async () => {
        try {
          const [clientResponse, memberResponse] = await Promise.all([
            getClientbyBusinessIdService(staticDetails.businessId),
            getMembersByBusinessIdService(staticDetails.businessId),
          ]);
  
          const clients = clientResponse?.data?.responseData?.clientData?.map((client, i) => {
            if (!client.clientName) {
              console.warn(`Invalid client at index ${i}:`, client);
              return null;
            }
            return {
              id: i + 1,
              label: client.clientName,
              value: client.clientName,
              isChecked: false,
            };
          }).filter(Boolean) || [];
  
          const members = memberResponse?.data?.responseData?.members?.map((member, i) => {
            if (!member.firstName || !member.lastName) {
              console.warn(`Invalid member at index ${i}:`, member);
              return null;
            }
            return {
              id: clients.length + i + 1,
              label: `${member.firstName} ${member.lastName}`,
              value: `${member.firstName} ${member.lastName}`,
              isChecked: false,
            };
          }).filter(Boolean) || [];
  
          const combinedData = [...clients, ...members];
          
  
          setFilterOwner(combinedData);
        } catch (error) {
          console.error("Error fetching data", error);
          setFilterOwner([]);
        }
      })();
    }
  }, [staticDetails]);
  
  

  useEffect(() => {
    applyFilters(searchSectionName, filterStatus, filterPriority, filterOwner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus, filterPriority, filterOwner]);

  useEffect(() => {
    if (isEditing && projectRowData !== null) {
      console.log(projectRowData, 'projectRowData')
      setOnOffState({
        isShareWithClient: projectRowData.isShareWithClient,
        isOnlySection: projectRowData.isOnlySection,
        isClientDueDate: projectRowData.isClientDueDate,
        clientDueDate: projectRowData.clientDueDate
      })


      const editedPlanName = projectRowData?.planName || "";
      const editedSections = projectRowData?.sections?.map(
        (section, sectionIndex) => ({
          id: sectionIndex + 1,
          sectionName: section?.sectionName,
          tasks: section?.tasks?.map((task, taskIndex) => ({
            id: taskIndex + 1,
            task: {
              name: task?.task?.name || "",
              color: task?.task?.color || "#000000",
            },
            status: {
              icon: task?.status?.icon || "",
              name: task?.status?.name || "",
              color: task?.status?.color || "",
              value: task?.status?.value || 0,
              isClick: task?.status?.isClick || false,
              position: task?.status?.position || 0,
              isSelected: task?.status?.isSelected || false,
            },
            owner: task?.owner || "",
            startDate: dateRender(task.startDate),
            internalDueDate: dateRender(task.internalDueDate),
            clientDueDate: dateRender(task.clientDueDate),
            priority: {
              icon: task?.priority?.icon || "",
              name: task?.priority?.name || "",
              color: task?.priority?.color || "",
              value: task?.priority?.value || 0,
              isClick: task?.priority?.isClick || false,
              position: task?.priority?.position || 0,
              isSelected: task?.priority?.isSelected || false,
            },
            completed: {
              name: task?.completed?.name || 0,
              progress: task?.completed?.progress || 0,
            },
          })),
          expanded: true,
        })
      );
      setPlanName(editedPlanName);
      setSections(editedSections);
      setFilteredSections(editedSections);
    } else {
      const initialSections = [
        {
          id: 1,
          sectionName: "",
          tasks: [
            {
              id: 1,
              task: { name: "", color: "#000000" },
              status: {
                icon: "",
                name: "",
                color: "",
                value: 0,
                isClick: false,
                position: 0,
                isSelected: false,
              },
              owner: "",
              startDate: "",
              internalDueDate: "",
              clientDueDate: "",
              priority: {
                icon: "",
                name: "",
                color: "",
                value: 0,
                isClick: false,
                position: 0,
                isSelected: false,
              },
              completed: {
                name: 0,
                progress: 0,
              },
            },
          ],
          expanded: true,
        },
      ];
      setPlanName("");
      setSections(initialSections);
      setFilteredSections(initialSections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  const handleBufferDaysBlur = (event, condition = true) => {
    const newSections = sections.map(({ ...section }) => ({
      ...section,
      tasks: section.tasks.map(({ ...task }) => ({
        ...task,
        clientDueDate:
          condition && event.target.value
            ? bufferDateChange(task.internalDueDate, event.target.value)
            : task.internalDueDate,
      })),
    }));
    setSections(newSections);
    setFilteredSections(newSections);
  };


  const handleToggleChange = (e) => {
    setOnOffState({
      ...onOffState,
      [e.target.name]: !onOffState[e.target.name]
    })
  }

  return (
    <>
      <div className="contract_main">
        <div
          className="contract_left_template"
          style={{
            maxHeight: "650px",
            padding: "0px 32px 25px",
          }}
        >
          <div className="d-flex justify-content-between my-4">
            <CustomToggle
              onChange={handleToggleChange}
              label="Share project plan with client :"
              name="isShareWithClient"
              checked={onOffState.isShareWithClient} />

            <div className="d-flex justify-content-between no_size">
              <CustomToggle
                onChange={handleToggleChange}
                label="Add buffer to client due date :"
                name="isClientDueDate"
                checked={onOffState.isClientDueDate} />

              <div className="_editable_">
                <input
                  style={{
                    border: '1px solid #e7e8ea',
                    borderRadius: '8px',
                    color: '#000',
                    fontSize: '14px',
                    outline: 'none !important',
                    padding: '15px',
                    width: '60px',
                    marginLeft: '20px'
                  }}
                  type="text"
                  placeholder="days"
                  name="clientDueDate"
                  value={onOffState.clientDueDate}
                  onChange={e => setOnOffState({...onOffState, clientDueDate: e.target.value})}
                  disabled={!onOffState.isClientDueDate} />

                <label className="font_14">Days</label>
              </div>
            </div>


          </div>
          <div>
            <CustomToggle
              onChange={handleToggleChange}
              label="Share only section level info :"
              subLabel="( Individual tasks in a section will not be visible to clients )"
              name="isOnlySection"
              checked={onOffState.isOnlySection} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "22px",
              alignItems: "center",
            }}
          >
            <div className="dealroom_detail_upper_left d-flex">
              <div className="dashboard_upper_input me-3">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchSectionName}
                  onChange={handleSearchSectionName}/>
                <img src={searchIcon} alt="searchIcon" />
              </div>
              <div className="dashboard_upper_input ">
                <div className="_editable_">
                  <input
                    type="text"
                    placeholder="Plan Name"
                    value={planName}
                    onChange={handlePlanNameChange}
                  />
                </div>
              </div>
            </div>
            <div className="dealroom_detail_upper_right _flex_gap_30">
              <CommonButton
                label="Filter"
                fontSize="14px"
                fontWeight="700"
                className="dash_common_btn white_bg_btn"
                icon={filterIcon}
                onClick={handleShowFilter}
              />
              {showFilter && (
                <ProjectDetailsFilter
                  handleFilterClose={() => setShowFilter(false)}
                  filterStatus={filterStatus}
                  filterPriority={filterPriority}
                  filterOwner={filterOwner}
                  setFilterStatus={setFilterStatus}
                  setFilterPriority={setFilterPriority}
                  setFilterOwner={setFilterOwner}
                />
              )}
              <CommonButton
                label="Add Section"
                fontSize="14px"
                fontWeight="700"
                className="dash_common_btn"
                icon={plusIcon}
                onClick={addRow}
              />
            </div>
          </div>
          <div
            style={{
              maxHeight: "300px",
              overflowY: "auto",
              scrollBehavior: "smooth",
              margin: "40px 0",
            }}
          >
            <table
              className="project_table dealroom_table"
              style={{ marginTop: 0 }}
            >
              <thead style={{ position: "sticky", top: -1, zIndex: 1 }}>
                <tr>
                  <th>Task</th>
                  <th>Status</th>
                  <th>Owner</th>
                  <th>Start Date</th>
                  <th>Internal Due Date</th>
                  <th>Client Due Date</th>
                  <th>Priority</th>
                  <th>Completed</th>
                </tr>
              </thead>
              <tbody>
                {filteredSections.length > 0 ? (
                  filteredSections.map((row, index) => (
                    <React.Fragment key={row.id}>
                      <tr
                        ref={
                          index === filteredSections.length - 1
                            ? newRowRef
                            : null
                        }
                      >
                        <td colSpan={8} style={{ textAlign: "left" }}>
                          <img
                            src={deleteIcon}
                            alt="delete"
                            onClick={() => deleteRow(row.id)}
                            style={{
                              marginLeft: 7,
                              marginRight: 10,
                              cursor: "pointer",
                              width: 20,
                              height: 20,
                            }}
                          />
                          {row.expanded ? (
                            <img
                              src={arrowBottomIcon}
                              alt="collapse"
                              style={{
                                transform: "rotate(-180deg)",
                                marginRight: 10,
                                cursor: "pointer",
                              }}
                              onClick={() => toggleExpand(row.id)}
                            />
                          ) : (
                            <img
                              src={arrowBottomIcon}
                              alt="collapse"
                              style={{ marginRight: 10, cursor: "pointer" }}
                              onClick={() => toggleExpand(row.id)}
                            />
                          )}
                          <input
                            type="text"
                            value={row.sectionName}
                            placeholder="Section Name"
                            onChange={(e) =>
                              handleInputChange(e, row.id, "sectionName")
                            }
                            style={{
                              width: "30%",
                              border: "none",
                              backgroundColor: "transparent",
                              fontSize: "16px",
                              fontWeight: 600,
                              lineHeight: "24px",
                            }}
                          />
                        </td>
                      </tr>
                      {row.expanded &&
                        row.tasks.map((subRow, subRowIndex) => (
                          <React.Fragment key={subRow.id}>
                            <tr>
                              <td
                                style={{
                                  display: "flex",
                                  gap: "20px",
                                  width: "100%",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={deleteIcon}
                                  alt="delete"
                                  onClick={() =>
                                    deleteSubRow(row.id, subRow.id)
                                  }
                                  style={{
                                    marginLeft: 25,
                                    cursor: "pointer",
                                    width: 15,
                                    height: 15,
                                  }}
                                />
                                <input
                                  type="text"
                                  value={subRow.task.name}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      row.id,
                                      "task",
                                      true,
                                      subRow.id
                                    )
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      handleAddTask(row.id);
                                    }
                                  }}
                                  id={`task-input-${row.id}-${subRow.id}`}
                                  placeholder="Task Name"
                                  style={{
                                    width: "100%",
                                    border: "none",
                                    color: subRow.task.color,
                                    backgroundColor: "transparent",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "21px",
                                  }}
                                />
                              </td>
                              <td>
                                <TableSelectComponent
                                  value={{
                                    label: subRow.status.name,
                                    value: subRow.status.name,
                                    color: subRow.status.color,
                                  }}
                                  options={projectStatus}
                                  onDropDownChange={(e) =>
                                    handleInputChange(
                                      e,
                                      row.id,
                                      "status",
                                      true,
                                      subRow.id
                                    )
                                  }
                                  showDot={true}
                                />
                              </td>
                              <td>
                                <TableSelectComponent
                                  value={{
                                    label: subRow.owner,
                                    value: subRow.owner,
                                  }}
                                  options={filterOwner}
                                  onDropDownChange={(e) =>
                                    handleInputChange(
                                      e,
                                      row.id,
                                      "owner",
                                      true,
                                      subRow.id
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <TableDateComponent
                                  date={subRow.startDate}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      row.id,
                                      "startDate",
                                      true,
                                      subRow.id
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <TableDateComponent
                                  date={subRow.internalDueDate}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      row.id,
                                      "internalDueDate",
                                      true,
                                      subRow.id
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <TableDateComponent
                                  date={subRow.clientDueDate}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      row.id,
                                      "clientDueDate",
                                      true,
                                      subRow.id
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <TableSelectComponent
                                  value={{
                                    label: subRow.priority.name,
                                    value: subRow.priority.name,
                                    color: subRow.priority.color,
                                  }}
                                  options={priorityStatus}
                                  onDropDownChange={(e) =>
                                    handleInputChange(
                                      e,
                                      row.id,
                                      "priority",
                                      true,
                                      subRow.id
                                    )
                                  }
                                />
                              </td>
                              <td
                                style={{
                                  display:
                                    subRow.completed.progress > 0
                                      ? "flex"
                                      : "block",
                                  gap: "15px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                {subRow.completed.progress !== 0 && (
                                  <progress
                                    value={subRow.completed.progress}
                                    max={1}
                                    style={{ width: "45%" }}
                                  />
                                )}
                                <TableSelectComponent
                                  value={{
                                    label: `${subRow.completed.name}%`,
                                    value: subRow.completed.name,
                                  }}
                                  options={completedStatus}
                                  onDropDownChange={(e) =>
                                    handleInputChange(
                                      e,
                                      row.id,
                                      "completed",
                                      true,
                                      subRow.id
                                    )
                                  }
                                />
                              </td>
                            </tr>
                            {subRowIndex === row.tasks.length - 1 && (
                              <tr>
                                <td colSpan={8} style={{ textAlign: "left" }}>
                                  <span
                                    style={{
                                      marginLeft: "55px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleAddTask(row.id)}
                                  >
                                    <img
                                      src={plusBlueIcon}
                                      alt="blue"
                                      style={{ marginTop: -3, marginRight: 10 }}
                                    />
                                    Add Task
                                  </span>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                      {row.expanded && row.tasks.length === 0 && (
                        <tr>
                          <td colSpan={8} style={{ textAlign: "left" }}>
                            <span
                              style={{ marginLeft: "55px", cursor: "pointer" }}
                              onClick={() => handleAddTask(row.id)}
                            >
                              <img
                                src={plusBlueIcon}
                                alt="blue"
                                style={{ marginTop: -3, marginRight: 10 }}
                              />
                              Add Task
                            </span>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} style={{ textAlign: "center" }}>
                      No Section Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="contract_main_button">
        <CommonButton
          label="Cancel"
          fontSize="14px"
          fontWeight="700"
          className="dash_common_btn white_bg_btn"
          icon={false}
          onClick={isEditing ? handleDiscardOpen : handleCancel}
        />
        <CommonButton
          label="Save Project Plan"
          fontSize="14px"
          fontWeight="700"
          className="dash_common_btn"
          icon=""
          isLoad={isLoading}
          onClick={handleSavePlan}
        />
      </div>

      {isDiscard && (
        <DiscardModal
          title="Discard changes?"
          isOpen={isDiscard}
          onClose={handleDiscardClose}
          onSure={handleDiscardClose}
          saveLabel="Save"
        />
      )}
    </>
  );
};

export default AddProjectPlan;
