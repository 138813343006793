import { useEffect, useState } from "react";
import { Dropdown } from 'react-bootstrap';
import Select from "react-select";
import plusIcon from "./../../../assets/dashboard/plus.svg";
import { clientDeleteService, getClientbyProjectIdService, getMembersbyProjectIdService, getProjectByBusinessService, memberDeleteService } from "../../../services";
import TableView from "./TableView";
import AddClientModal from "./AddClientModal";
import { StarIcon, TeamIcon } from "../../../components/SVG";
import AddTeamModal from "./AddTeamModal";

const ProjectTeam = ({isGlobal}) => {
    const projectDetail = JSON.parse(localStorage.getItem('projectDetail'));
    const userDetail = JSON.parse(localStorage.getItem("authUserData")) || null;

    const [clientList, setClientList] = useState([]);
    const [memberList, setMemberList] = useState([]);
    const [addClientModal, setAddClientModal] = useState(false);
    const [addTeamModal, setAddTeamModal] = useState(false);
    const [projectsList, setProjectList] = useState([]);
    const [selectedProject, setSelectedProject] = useState({});


    const handleModalToggle = () => setAddClientModal(prev => !prev);
    const handleModalToggle2 = () => setAddTeamModal(prev => !prev);



    const getAllProjects = async () => {
        try {
            const res = await getProjectByBusinessService(userDetail?.businessDetail?.businessId);
            let _projects = res.data.responseData.projectData || [];
            if(_projects.length > 0) {
                _projects = _projects.map(item => ({...item, label: item.projectName, value: item.id}))
                setProjectList(_projects);
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };
    
    
    useEffect(() => {
        if(userDetail?.businessDetail?.businessId && isGlobal){
            getAllProjects();
        }
        
    }, [isGlobal])



    const fetchProjectData = async (service, setData, dataKey) => {
        try {

            const projectId = projectDetail?.id || '';
            const businessId = userDetail?.businessDetail?.businessId;
            let params = `?businessId=${businessId}`;
            if (!isGlobal || (isGlobal && selectedProject.value)) {
              const effectiveProjectId = isGlobal ? selectedProject.value : projectId;
              params += `&projectId=${effectiveProjectId}`;
            }
            const res = await service(params);
            if (res.data.status) {
                setData(res.data.responseData[dataKey] || []);
            }
        } catch (error) {
            console.error(`Error fetching ${dataKey} by project ID:`, error);
        }
    };

    useEffect(() => {
        fetchProjectData(getClientbyProjectIdService, setClientList, 'clients');
        fetchProjectData(getMembersbyProjectIdService, setMemberList, 'members');
    }, []);


    const cmCallback = type => {
        if(type == 'members'){
            fetchProjectData(getMembersbyProjectIdService, setMemberList, 'members');
        }

        if(type == 'clients'){
            fetchProjectData(getClientbyProjectIdService, setClientList, 'clients');
        }
    }


    const handleDeleteModal = async (id, type) => {
        let _service = (type === 'client' ? clientDeleteService : memberDeleteService);
    
        try {
            const res = await _service(id);
    
            if (type === 'client') {
                await fetchProjectData(getClientbyProjectIdService, setClientList, 'clients');
            } else {
                await fetchProjectData(getMembersbyProjectIdService, setMemberList, 'members');
            }
        } catch (error) {
            console.error('Error deleting:', error);
        }
    };


    useEffect(() => {
        document.getElementById('remove_btn_class').classList.remove('btn')
    }, [])
    

    return (
        <div className="file_content_wrapper">
            <div className="col-md-4">
                {isGlobal ?  <Select
                                classNamePrefix="cm_ui_select"
                                value={selectedProject.value ? selectedProject : ''}
                                onChange={e => setSelectedProject(e)}
                                options={[
                                    {label: 'All Projects', value: ''},
                                    ...projectsList
                                ]}
                                placeholder="All Projects" /> : ''}
            </div>
            <SectionHeader title="Client">

            <div className="cm_drop_dwon cm_drop_dwon2">
                <Dropdown>
                    <Dropdown.Toggle  className="common-button shadow dash_common_btn px-5" variant="primary" id="remove_btn_class">
                        <img src={plusIcon} alt='icon'/> <b> Add</b> 
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={handleModalToggle} href="javascript:void(0)"><StarIcon /> <span className="ms-2">Add Client</span></Dropdown.Item>
                        <Dropdown.Item onClick={handleModalToggle2} href="javascript:void(0)"><TeamIcon /> <span className="ms-2">Add Team Member</span></Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            


            </SectionHeader>
            <TableView deleteCallback={(id) => handleDeleteModal(id, 'client')} list={clientList} />

            <SectionHeader title="Team" />
            <p>
                <small>
                    Only existing team members can be added to a project. To add new team members to the account, head to account settings.
                </small>
            </p>
            <TableView deleteCallback={(id) => handleDeleteModal(id, 'member')} list={memberList} />

            {addClientModal && <AddClientModal cmCallback={cmCallback} onHide={handleModalToggle} />}
            {addTeamModal && <AddTeamModal cmCallback={cmCallback} onHide={handleModalToggle2} />}
        </div>
    );
};

const SectionHeader = ({ title, children }) => (
    <div className="d-flex justify-content-between align-items-center mt-5">
        <h3 className="mb-0 teams_heading">{title}</h3>
        {children}
    </div>
);

export default ProjectTeam;
