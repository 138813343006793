import { useEffect, useState } from "react";
import { getBrandDetailService, onboardingBusinessService, uploadLogoService } from "../../services";
import useToast from "../../utils/customHooks/useToast";
import PhoneNumber from "../../components/PhoneNumber";
import imageIconOutline from "../../assets/dashboard/image-icon-outline.svg";
import {industry as industryList} from "../../utils/constant";
import { separateCountryCodeAndNumber } from "../../utils/helper";


const BrandSettings = () => {
    const authUserData = JSON.parse(localStorage.getItem("authUserData")) || null;
    const token = localStorage.getItem('token');
    const showToast = useToast()

    const [businessName, setBusinessName] = useState('');
    const [website, setWebsite] = useState('');
    const [industry, setIndustry] = useState('');
    const [country_code, setCountry_code] = useState('');
    const [phone_number, setPhone_number] = useState('');
    const [email, setEmail] = useState('');
    const [businessAddress, setBusinessAddress] = useState('');
    const [logo, setLogo] = useState('');

    const [businessNameErr, setBusinessNameErr] = useState('');
    const [websiteErr, setWebsiteErr] = useState('');
    const [industryErr, setIndustryErr] = useState('');
    const [country_codeErr, setCountry_codeErr] = useState('');
    const [phone_numberErr, setPhone_numberErr] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [businessAddressErr, setBusinessAddressErr] = useState('');



    const [isSubmitting, setIsSubmitting] = useState(false);



    const getBrandDetail = async () => {
        try {
            let query = `?businessId=${authUserData.businessDetail.businessId}`;
            const res = await getBrandDetailService(query, token);
            if (res?.data?.status) {
                let _data = res.data.responseData;
                setBusinessName(_data.businessName);
                setWebsite(_data.website);
                setIndustry(_data.industry);
                setEmail(_data.email);
                setBusinessAddress(_data.businessAddress);
                setLogo(_data.logo);
                setPhone_number(`${_data.country_code} ${_data.phone_number}`);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (authUserData?.businessDetail?.businessId) {
            getBrandDetail();
        }
    }, []);


    const handleValidate = () => {
        let validate = true;

        if(!businessName){
            setBusinessNameErr('Business name is required');
            validate = false
        }

        // if(!website){
        //     setWebsiteErr('Website is required');
        //     validate = false
        // }

        if(!industry){
            setIndustryErr('Industry is required');
            validate = false
        }

        if(!phone_number){
            setPhone_numberErr('Phone number is required');
            validate = false
        }

        if(!email){
            setEmailErr('Email is required');
            validate = false
        }

        if(!businessAddress){
            setBusinessAddressErr('Business address is required');
            validate = false
        }

        return validate
    }


    const handleSubmit = async () => {
        if(handleValidate()){
            const { cc, number } = separateCountryCodeAndNumber(phone_number);

            let params = {
                businessName,
                website,
                industry,
                country_code: cc,
                phone_number: parseInt(number),
                email,
                businessAddress,
                businessId: authUserData.businessDetail.businessId
            }
            setIsSubmitting(true);
            try {
                const res = await onboardingBusinessService(params);
                if (res?.data?.status) {
                    showToast(`Your business account details have been successfully updated.`, "success");
                }
            } catch (error) {
                console.error(error);
            }
            setIsSubmitting(false);
        }
    };



    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('logo', file);
        uploadLogoService(formData, token).then(res => {
            if(res.data.status){
                showToast(`Your Logo has been successfully updated.`, "success");
                getBrandDetail();
            }
        })
      };


    return (
        <div className="setting_inner_page">
            <h3>Brand Settings</h3>


            <div className="row">
                <div className="col-lg-10">
                    <div className="cm_profile_upload">
                        <div className="cm_profile_upload_inner">
                            <input value="" accept="image/*" onChange={handleFileChange} type="file" />
                            {logo ? <img className="pmm1" src={logo} alt="Profile" /> : <img className="pmm2" src={imageIconOutline} alt="Upload Icon" />}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">

                <div className="col-lg-5">
                    <div className="form-group mb-4">
                        <label>Business Name</label>
                        <input
                            type="text"
                            value={businessName}
                            onChange={e => (setBusinessName(e.target.value), setBusinessNameErr(''))}
                            className="form-control"
                            placeholder="Business Name" />

                        {businessNameErr && <span className="cm_err">{businessNameErr}</span>}
                    </div>
                </div>


                <div className="col-lg-5">
                    <div className="form-group mb-4">
                        <label>Business Website</label>
                        <input
                            type="text"
                            value={website}
                            onChange={e => (setWebsite(e.target.value), setWebsiteErr(''))}
                            className="form-control"
                            placeholder="Website"  />

                        {websiteErr && <span className="cm_err">{websiteErr}</span>}
                    </div>
                </div>

                    
            </div>

            <div className="row">
                <div className="col-lg-5">
                    <div className="form-group mb-4">
                        <label>Industry</label>
                        <select
                        value={industry}
                        onChange={e => (setIndustry(e.target.value), setIndustryErr(''))}
                        className="form-control"
                        placeholder="Industry">
                            {industryList.map(item => (<option value={item.label}>{item.label}</option>))}
                        </select>
                       

                        {industryErr && <span className="cm_err">{industryErr}</span>}
                    </div>
                </div>

                <div className="col-lg-5">
                    <div className="form-group mb-4">
                        <label>Phone Number</label>
                        <PhoneNumber
                            value={phone_number}
                            setValue={(value) => (setPhone_number(value), setPhone_numberErr(''))}
                            isError={!!phone_numberErr}
                            setError={() => {}}
                            isSubmitTouch={true}/>

                        {phone_numberErr && <span className="cm_err">{phone_numberErr}</span>}
                    </div>
                </div>
            </div>



            <div className="row">

                <div className="col-lg-5">
                    <div className="form-group mb-4">
                        <label>Business Email</label>
                        <input
                            type="text"
                            value={email}
                            onChange={e => (setEmail(e.target.value), setEmailErr(''))}
                            className="form-control"
                            placeholder="Email" />

                        {emailErr && <span className="cm_err">{emailErr}</span>}
                    </div>
                </div>


                <div className="col-lg-5">
                    <div className="form-group mb-4">
                        <label>Business Address</label>
                        <input
                            type="text"
                            value={businessAddress}
                            onChange={e => (setBusinessAddress(e.target.value), setBusinessAddressErr(''))}
                            className="form-control"
                            placeholder="Address"  />

                        {businessAddressErr && <span className="cm_err">{businessAddressErr}</span>}
                    </div>
                </div>

                    
            </div>

            

            <div className="row">
                <div className="col-lg-10 mt-5 text-end">
                    <button disabled={isSubmitting} className="btn btn-primary px-5" onClick={handleSubmit}>Save</button>
                </div>
            </div>
        </div>
    );
};

export default BrandSettings;







