export function separateCountryCodeAndNumber(value) {
  const parts = value.split(" ");
  const countryCode = parts[0];
  const number = parts.slice(1).join(" ").trim().replace(/\s/g, ""); 

  return {
    cc: countryCode,
    number: number,
  };
}

export const validateEmail = (email) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const now = new Date();

  const isToday = date.toDateString() === now.toDateString();
  const isYesterday =
    date.toDateString() ===
    new Date(now.setDate(now.getDate() - 1)).toDateString();

  const time = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  if (isToday) {
    return `Today, ${time}`;
  } else if (isYesterday) {
    return `Yesterday, ${time}`;
  } else {
    return `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}, ${time}`;
  }
};

export function replaceNewlinesWithCommas(str) {
  // Trim any extra newline characters at the end of the string
  str = str.replace(/\n+$/, "");
  // Replace remaining newline characters with commas
  return str.replace(/\n/g, ",");
}

// Function to capitalize the first letter of a string
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const hexToRgb = (hex) => {
  // Remove the leading '#' if present
  hex = hex.replace(/^#/, "");

  // Parse the r, g, b values
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  // Return the RGB color in the desired format
  return `rgb(${r / 255}, ${g / 255}, ${b / 255})`;
};

export const hexToRgba = (hex, opacity) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r},${g},${b},${opacity})`;
};

export const hexToRgbValues = (hex) => {
  hex = hex.replace(/^#/, "");
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return [r / 255, g / 255, b / 255];
};

export const createSchema = (signers) => {
  const defaultValues = {
    clientFirstName: "",
    clientLastName: "",
    clientAddress: "",
    clientEmail: "",
    clientInitials: "",
    clientSignature: "",
    companyName: "",
    companyAddress: "",
    companyPhoneNumber: "",
    projectName: "",
    projectAddress: "",
    projectDueDate: "",
  };

  const schema = { ...defaultValues };


  return schema;
};


export async function pdfBlobUrlToFile(blobUrl, fileName) {
  try {
    console.log(blobUrl, fileName, "Fetching Blob URL...");

    // Fetch the Blob from the Blob URL
    const response = await fetch(blobUrl);

    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.statusText}`);
    }

    const blob = await response.blob();

    // Create a PDF File from the Blob
    const file = new File([blob], fileName, { type: "application/pdf" });

    return file;
  } catch (error) {
    console.error("Error fetching blob URL:", error);
    throw error;
  }
}

export async function base64ImageToFile(base64String, fileName) {
  try {
    const response = await fetch(base64String);
    const blob = await response.blob();
    const file = new File([blob], fileName, { type: blob.type });
    return file;
  } catch (error) {
    console.error("Error converting Base64 to File:", error);
    throw error;
  }
}

export function isBase64Image(value) {
  // Check if the value starts with "data:image/"
  return typeof value === "string" && value.startsWith("data:image/");
}

export const clientformatDate = (comingDate) => {
  // Create a new Date object from the string
  const date = new Date(comingDate);

  // Define options for formatting the date
  const options = { day: "2-digit", month: "long", year: "numeric" };

  // Format the date
  const formattedDate = date.toLocaleDateString("en-GB", options);

  // Split the formatted date
  const [day, month, year] = formattedDate.split(" ");

  // Format the desired output
  const finalFormattedDate = `${month} ${day}, ${year}`;

  return finalFormattedDate;
};

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + +days);
  return result;
};

export const subtractDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() - +days);
  return result;
};


export const formatDate2 = (date) => {
  if (!date) return ''; // Handle empty date input
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit'
  });
};


export const getFileTypeCode = (fileType) => {
  if (!fileType) return null; // No file provided
  if (fileType?.startsWith('image/')) {
      return 1; // PHOTO
  } else if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return 2; // DOCX
  } else if (fileType === 'application/pdf') {
      return 3; // PDF
  } else {
      return 0; // Unknown or unsupported file type
  }
};


export const formatDateMMddhhmmss = (now) => {
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hour = String(now.getHours()).padStart(2, '0');
  const minute = String(now.getMinutes()).padStart(2, '0');
  const second = String(now.getSeconds()).padStart(2, '0');
  return `${month}${day}${hour}${minute}${second}`;
}

export const formatAmount = (amount) => {
  // Check if the amount is undefined or null
  if (amount === undefined || amount === null) {
      return '0'; // Or return a default value of your choice
  }

  // Convert to number and ensure two decimal places
  const amountNumber = Number(amount);
  const amountString = amountNumber.toFixed(2); // Ensures two decimal places

  let [integerPart, decimalPart] = amountString.split('.');

  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);
  
  if (otherDigits) {
      lastThreeDigits = ',' + lastThreeDigits;
  }
  
  let formattedInteger = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
}



export function formatDateRange(dateString) {
  const year = parseInt(dateString.slice(0, 4), 10);
  const month = parseInt(dateString.slice(4, 6), 10);
  const day = parseInt(dateString.slice(6, 8), 10);
  
  const startDate = new Date(year, month - 1, day);
  const endDate = new Date();
  const dayCount = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
  
  const optionsWithoutYear = { month: 'short', day: 'numeric' };
  const optionsWithYear = { month: 'short', day: 'numeric', year: 'numeric' };
  
  const formattedStartDate = startDate.getFullYear() === endDate.getFullYear()
      ? startDate.toLocaleDateString('en-US', optionsWithoutYear)
      : startDate.toLocaleDateString('en-US', optionsWithYear);
      
  const formattedEndDate = endDate.toLocaleDateString('en-US', optionsWithYear);
  
  return `last ${dayCount} days (${formattedStartDate} - ${formattedEndDate})`;
}
